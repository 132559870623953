import { useState, createFactory } from 'react';

const SlidesController = (onSlideChange, arrayProp = 'records') => BaseComponent => {
  const factory = createFactory(BaseComponent);
  const Wrapper = (props) => {
    const [state, setState] = useState(props.defaultIndex || 0);

    const gotoNext = () => {
      if(state < props[arrayProp].length - 1){
        setState(state + 1);
      }
    };
    const gotoPrev = () => {
      if(state > 0){
        setState(state - 1);
      }
    };

    const gotoIndex = index => {
      setState(index);
    };

    const _onSlideChange = index => {
      if(onSlideChange){
        onSlideChange(index, props);
      }
      if(index !== state) {
        setState(index);
      }
    };

    return factory({
      ...props,
      gotoNext,
      gotoPrev,
      onSlideChange: _onSlideChange,
      currentIndex: state,
      gotoIndex
    });
  };
  return Wrapper;
};

export default SlidesController;
