import React from 'react';
import { connect } from 'react-redux';
import {
  IonHeader,
  IonContent,
  IonItemGroup,
  IonItemDivider,
  IonItem,
  IonLabel,
  IonAvatar,
  IonList,
  IonProgressBar,
  IonRefresher,
  IonRefresherContent,
  IonPage
} from '@ionic/react';
import './Attendees.css';

import Header from '../components/Header';
import VisitHeader from '../components/VisitHeader';
import NotFoundList from '../components/NotFoundList';

import { capitalize } from '../utils/utils';
import language from '../custom-scripts/language';

import { getAttendees } from '../features/attendees/actions';
import { selectAttendeesPage } from '../features/attendees/selectors';

const pushAttendee = (history, id) => e => {
  e.preventDefault();
  history.push(`/attendees/attendee?attendeeId=${encodeURIComponent(id)}`);
};

const Attendees = ({attendees = {}, records = [], visit, visitLoading, history, doRefresh}) => (
  <IonPage>
    <IonHeader>
      <Header history={history} />
    </IonHeader>

    <IonContent color="light">
      { visitLoading && attendees.loading && <IonProgressBar type="indeterminate"></IonProgressBar> }

      <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
        <IonRefresherContent color="light"></IonRefresherContent>
      </IonRefresher>

      <VisitHeader visit={visit} />

      { records.length > 0 &&
        <IonList className="list top-round attendee-list">
          <IonItemGroup>
            <IonItemDivider sticky>
              <IonLabel className="attendees-title">
                Attendees
              </IonLabel>
            </IonItemDivider>

            { records.map((attendee) => (
              <IonItem className="attendee-item" key={attendee.id} button onClick={pushAttendee(history, attendee.id)} lines="full">
                <div className="attendee-profile-small">
                  <IonAvatar slot="start">
                    <img src={attendee.image || '/user.png'} alt={attendee.name}/>
                  </IonAvatar>
                </div>
                <IonLabel>
                  <h3>{attendee.name}</h3>
                  { (attendee.isPresenter || attendee.title) && <p>
                    { attendee.isPresenter && <span className="speaker-chip">{capitalize(language.speaker)}</span> }
                    {attendee.title}
                  </p> }
                  <p>{attendee.company}</p>
                </IonLabel>
              </IonItem>
            )) }

          </IonItemGroup>
        </IonList>
      }
      { (!visitLoading && !attendees.loading && !attendees.errorMessage && records.length === 0) &&
          <NotFoundList iconSrc="/icons/icon-user.svg" title="No Attendees" description="There are no attendees listed for this visit."/>
      }
      { (!visitLoading && !attendees.loading && attendees.errorMessage) &&
          <NotFoundList iconSrc="/icons/icon-error.svg" title="Error loading attendees" description={attendees.errorMessage} />
      }
    </IonContent>
  </IonPage>
);

const mapDispatch = dispatch => {
  const doRefresh = (event) => {
    dispatch(getAttendees({disableCache: true})).then(() => {
      event.detail.complete();
    }).catch(() => {
      event.detail.complete();
    });
  };
  return {
    doRefresh
  };
};

export default connect(
  selectAttendeesPage,
  mapDispatch
)(Attendees);
