import { connect } from 'react-redux';
import React from 'react';
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonContent,
  IonTitle,
  IonButton,
  IonProgressBar,
} from '@ionic/react';
import { selectLocationModal } from '../features/locations/selectors';
//import './Location.css';

const LocationModal = ({location, isLoading, hide}) => (
  <>
    <IonHeader>
      <IonToolbar color="light">
        <IonTitle>Location</IonTitle>
        <IonButtons slot="end">
          <IonButton onClick={hide}>Close</IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>

    <IonContent color="light agenda-session location-content">
      { isLoading && <IonProgressBar type="indeterminate"></IonProgressBar> }
      { !isLoading &&
      <>
        <div className="subheader-padding">
          <h2>{location.name}</h2>
          <p className="dates">
            <a className="location-link" href={location.mapQuery}>
              {location.locationStreetAddress} <br/>
              {location.locationCity}, {location.locationStateOrProvince}  {location.locationPostalCodeOrZip} <br/>
              {location.locationCountry}
            </a>
          </p>
        </div>
        <div className="list top-round agenda-list">
          <div className="agenda-item-content">
            <h3>Location Map</h3>
            <p><img src={location.mapImageSrc} alt='map of location' /></p>
            { /* TODO: There was a places of interest - but not sure if that's been implemented.
            <h3>Places of Interest</h3>
              <p>TODO - insert some static content as a placeholder</p>
            */}
          </div>
        </div>
      </>
      }
    </IonContent>
  </>
);

const mapStateToProps = selectLocationModal;

export default connect(
  mapStateToProps
)(LocationModal);
