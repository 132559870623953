const initialState = { };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CHANGE_PROFILE_IMAGE':{
      if (action.state === 'REQUEST') {
        return {
          ...state,
          isLoading: true,
          errorMessage: ''
        };
      } else {
        return {
          ...state,
          isLoading: false,
          errorMessage: action.e
        };
      }
    }
    default: {
      return state;
    }
  }
};

export default reducer;
