import React, { useState } from 'react';
import {
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonToast,
  IonButtons,
  IonIcon
} from '@ionic/react';
import { chevronBack } from 'ionicons/icons';
import {
  MODAL_FORGOT_PASSWORD,
  MODAL_LOGIN,
} from '../features/auth/modalConsts';
import { updateStateObjFromInput } from '../utils/utils';
import Input from '../components/Input';
import ErrorMessage from '../components/ErrorMessage';

export const ConfirmationCodeForm = ({
  closeModal,
  submitConfirmationCode,
  resendConfirmationCode,
  errorMessage,
  loading
}) => {
  const [formState, setFormState] = useState({});
  const updateInput = updateStateObjFromInput(setFormState);
  const [showToast, setShowToast] = useState(false);
  const submitForm = e => {
    e.preventDefault();
    submitConfirmationCode(formState).then(() => {
      closeModal();
    });
  };
  const resendCodeAndShowMessage = e => {
    e.preventDefault();
    resendConfirmationCode().then(() => {
      setShowToast(true);
    });
  };
  return (
    <form onSubmit={submitForm}>
      <IonList>
        <IonItem>
          <IonLabel>
            Please enter the confirmation code emailed to you.
          </IonLabel>
        </IonItem>
        <Input type="text" prop="confirmationCode" state={formState} onChange={updateInput} title="Confirmation Code"/>
        <ErrorMessage errorMessage={errorMessage}/>
      </IonList>
      <IonButton expand="block" type="submit" disabled={loading}>Submit</IonButton>
      <IonButton expand="block" type="button" fill="clear" onClick={resendCodeAndShowMessage} disabled={loading}>Resend Confirmation Code</IonButton>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message="The confirmation code was sent"
        duration={10e3}
      />
    </form>
  );
};

export const ForgotPasswordForm = ({
  sendVerificationCode,
  errorMessage,
  loading
}) => {
  const [formState, setFormState] = useState({});
  const updateInput = updateStateObjFromInput(setFormState);
  const submitForm = e => {
    e.preventDefault();
    sendVerificationCode(formState).catch();
  };
  return (
    <form onSubmit={submitForm}>
      <IonList>
        <IonItem>
          Please enter your email.
        </IonItem>
        <Input type="email" prop="email" state={formState} onChange={updateInput} title="Email"/>
        <ErrorMessage errorMessage={errorMessage}/>
      </IonList>
      <IonButton expand="block" type="submit" disabled={loading}>Submit</IonButton>
    </form>
  );
};

export const NewPasswordForm = ({
  closeModal,
  submitNewPassword,
  errorMessage,
  loading,
}) => {
  const [newPasswordState, setNewPasswordState] = useState({});
  const updateNewPasswordInput = updateStateObjFromInput(setNewPasswordState);
  const submitNewPasswordForm = e => {
    e.preventDefault();
    submitNewPassword(newPasswordState).then(() => {
      closeModal();
    });
  };

  return (
    <form onSubmit={submitNewPasswordForm}>
      <IonList>
        <IonItem lines="full">
          <IonLabel>
            Set Your New Password
          </IonLabel>
        </IonItem>
        <Input type="text" prop="verificationCode" state={newPasswordState} onChange={updateNewPasswordInput} title="Verification Code"/>
        <Input type="password" prop="newPassword" state={newPasswordState} onChange={updateNewPasswordInput} title="New Password"/>
        <Input type="password" prop="newPasswordConfirmed" state={newPasswordState} onChange={updateNewPasswordInput} title="New Password Confirmed"/>
        <ErrorMessage errorMessage={errorMessage}/>
      </IonList>
      <IonButton expand="block" type="submit" disabled={loading}>Reset</IonButton>
    </form>
  );
};

export const LoginForm = ({
  closeModal,
  login,
  errorMessage,
  loading,
  setModalState,
}) => {
  const [loginState, setLoginState] = useState({});
  const updateLoginInput = updateStateObjFromInput(setLoginState);
  const submitLoginForm = (e) => {
    e.preventDefault();
    return login(loginState).then(() => {
      closeModal();
    });
  };
  return (
    <form onSubmit={submitLoginForm}>
      <IonList>
        <Input type="email" prop="email" state={loginState} onChange={updateLoginInput} title="Email" required={true}/>
        <Input type="password" prop="password" state={loginState} onChange={updateLoginInput} title="Password" required={true}/>
        <ErrorMessage errorMessage={errorMessage}/>
      </IonList>
      <IonButton class="login-button" expand="block" type="submit" disabled={loading}>Login</IonButton>
      <IonButton class="login-button" fill="clear" expand="block" type="button" disabled={loading} onClick={() => setModalState(MODAL_FORGOT_PASSWORD)}>Forgot Password?</IonButton>
    </form>
  );
};

export const RegistrationForm = ({
  register,
  registerEmail,
  errorMessage,
  loading
}) => {
  const [registerState, setRegisterState] = useState({});
  const updateRegisterInput = updateStateObjFromInput(setRegisterState);
  const registerAndCloseModal = (e) => {
    e.preventDefault();
    register(registerState);
  };
  return (
    <form onSubmit={registerAndCloseModal}>
      <IonList>
        <Input prop='givenName' state={registerState} onChange={updateRegisterInput} title="First Name" type="text" required={true} />
        <Input prop='familyName' state={registerState} onChange={updateRegisterInput} title="Last Name" type="text" required={true} />
        <Input prop='email' state={Object.assign(registerState, {email: registerState.email || registerEmail})} onChange={updateRegisterInput} title="Email" type="email" required={true} disabled={!!registerEmail} />
        <Input prop='password' state={registerState} onChange={updateRegisterInput} title="Password" type="password" required={true} />
        <Input prop='confirmPassword' state={registerState} onChange={updateRegisterInput} title="Confirm Password" type="password" required={true} />
        <ErrorMessage errorMessage={errorMessage}/>
      </IonList>
      <IonButton expand="block" type="submit" disabled={loading}>Register</IonButton>
    </form>
  );
};

const backStates = {
  [MODAL_FORGOT_PASSWORD]: MODAL_LOGIN
};

export const ModalBack = ({
  modalState,
  setModalState
}) => {
  return Object.keys(backStates).some(state => modalState === state) && (
    <IonButtons slot="start" style={{marginLeft: '-15px'}}>
      <IonButton onClick={() => setModalState(backStates[modalState])} color="medium">
        <IonIcon size="large" icon={chevronBack}></IonIcon>
        Back
      </IonButton>
    </IonButtons>
  );
};
