import React from 'react';
import { connect } from 'react-redux';
import {
  IonContent,
  IonHeader,
  IonProgressBar,
  IonRefresher,
  IonRefresherContent,
  IonPage
} from '@ionic/react';
import { selectAgendaListPage } from '../features/agendasessions/selectors';
import { getAgendaSessions } from '../features/agendasessions/actions';

import './Schedule.css';

import AgendaList from '../components/AgendaList';
import Header from '../components/Header';
import VisitHeader from '../components/VisitHeader';
import NotFoundList from '../components/NotFoundList';


const SchedulePage = ({sessions, groupedItems, history, visit, doRefresh}) => (
  <IonPage>
    <IonHeader>
      <Header history={history} />
    </IonHeader>

    <IonContent color="light">
      { sessions.loading && <IonProgressBar type="indeterminate"></IonProgressBar> }

      <IonRefresher slot="fixed" onIonRefresh={doRefresh} color="light">
        <IonRefresherContent color="light"></IonRefresherContent>
      </IonRefresher>

      <VisitHeader visit={visit} />

      <div>
        { groupedItems.length > 0 &&
          <AgendaList groupedItems={groupedItems} />
        }
        { (!sessions.loading && !sessions.errorMessage && groupedItems.length === 0) &&
          <NotFoundList iconSrc="/icons/icon-cal.svg" title="No Agenda Sessions" description="There are no sessions planned for this visit."/>
        }
        { (!sessions.loading && sessions.errorMessage) &&
          <NotFoundList iconSrc="/icons/icon-error.svg" title="Error loading agenda" description={sessions.errorMessage} />
        }
      </div>
    </IonContent>
  </IonPage>
);

const mapDispatch = dispatch => {
  const doRefresh = (event) => {
    dispatch(getAgendaSessions({disableCache: true})).then(() => {
      event.detail.complete();
    }).catch(() => {
      event.detail.complete();
    });
  };
  return {
    doRefresh
  };
};

export default connect(
  selectAgendaListPage,
  mapDispatch
)(SchedulePage);

