import {
  AUTH_NOT_CHECKED,
  AUTH_IN_PROGRESS,
  AUTH_NO
} from './consts';

import {
  remoteActionSimpleReducer
} from '../../services/actionService';

import {
  MODAL_CONFIRMATION_CODE_REQUIRED,
  MODAL_FORGOT_PASSWORD,
  MODAL_SUBMIT_NEW_PASSWORD
} from './modalConsts';
import { path, pathEq, pipe, assoc } from 'ramda';
import qs from 'qs';

const initialState = {
  status: AUTH_NOT_CHECKED,
  modal: '',
  modalState: '',
  loading: false,
  errorMessage: '',
  id: undefined,
  registerEmail: undefined,
  username: undefined,
  password: undefined,
  tokens: {},
  requiredPasswordResetData: undefined
};

const reducer = (state = initialState, action) => {
  switch(action.type){
    case 'AUTH_CLEAR_ERROR': {
      return {
        ...state,
        errorMessage: '',
      };
    }
    case 'AUTH_CHECK':
    case 'AUTH_LOGIN':{
      if(action.state === 'REQUEST'){
        return {
          ...state,
          status: AUTH_IN_PROGRESS,
          loading: true,
          errorMessage: '',
          username: path(['metadata', 'username'], action),
          password: path(['metadata', 'password'], action),
        };
      } else if(action.state === 'RESPONSE') {
        return {
          ...state,
          loading: false,
          tokens: action.data
        };
      } else {
        if(action.e.code === 'UserNotConfirmedException') {
          return {
            ...state,
            modalState: MODAL_CONFIRMATION_CODE_REQUIRED,
            loading: false
          };
        }
        return {
          ...state,
          status: AUTH_NO,
          loading: false,
          errorMessage: action.error
        };
      }
    }

    case 'AUTH_INIT_FORGOT_PASSWORD':
    case 'AUTH_SUBMIT_NEW_PASSWORD':
    case 'AUTH_CHANGE_PASSWORD':
    case 'AUTH_RESEND_CONFIRMATION_CODE':
    case 'AUTH_REQUIRED_PASSWORD_RESET':{
      return remoteActionSimpleReducer(action, state);
    }

    case 'AUTH_SUBMIT_CONFIRMATION_CODE':{
      return pipe(
        state => remoteActionSimpleReducer(action, state),
        state => action.state === 'RESPONSE' ?
          assoc('status', AUTH_NOT_CHECKED, state) :
          state
      )(state);
    }

    case 'AUTH_REGISTER':{
      if(action.state === 'REQUEST'){
        return {
          ...state,
          loading: true,
          errorMessage: '',
          username: path(['metadata', 'username'], action),
          password: path(['metadata', 'password'], action)
        };
      } else if(action.state === 'RESPONSE') {
        return {
          ...state,
          modalState: MODAL_CONFIRMATION_CODE_REQUIRED,
          loading: false,
        };
      } else {
        return {
          ...state,
          loading: false,
          errorMessage: action.error
        };
      }
    }

    case '@@router/LOCATION_CHANGE':{
      if (pathEq(['payload', 'location', 'pathname'], '/login', action)) {
        const queryParams = path(['payload', 'location', 'search'], action);
        const search = qs.parse(queryParams, { ignoreQueryPrefix: true });
        return {
          ...state,
          registerEmail: search.email,
          modal: !!search.state,
          modalState: search.state,
        };
      }
      return state;
    }

    case 'AUTH_SET_MODAL_STATE':{
      return {
        ...state,
        errorMessage: '',
        modalState: action.payload
      };
    }

    case 'AUTH_SET_MODAL':{
      return {
        ...state,
        modal: action.payload
      };
    }

    case 'AUTH_STATUS_CHANGE':{
      return {
        ...state,
        status: action.payload
      };
    }

    case 'AUTH_FORGOT_PASSWORD': {
      return {
        ...state,
        isLoading: false,
        errorMessage: '',
        modalState: MODAL_FORGOT_PASSWORD
      };
    }

    case 'AUTH_SEND_VERIFICATION': {
      if (action.state === 'REQUEST') {
        return {
          ...state,
          isLoading: true,
          errorMessage: '',
        };
      } else if (action.state === 'RESPONSE') {
        return {
          ...state,
          modalState: MODAL_SUBMIT_NEW_PASSWORD,
          isLoading: false,
          errorMessage: '',
        };
      } else {
        if (action.e.code === 'NEW_PASSWORD_REQUIRED') {
          return {
            ...state,
            modalState: MODAL_SUBMIT_NEW_PASSWORD,
            isLoading: false,
            errorMessage: '',
            requiredPasswordResetData: action.e.metadata,
          };
        }
        return {
          ...state,
          isLoading: false,
          errorMessage: action.error
        };
      }
    }
    default:
      return state;
  }
};

export default reducer;


