import { pipe, assoc } from 'ramda';

const initialState = {
  loading: false,
  records: [],
  errorMessage: '',
};

const reducer = (state = initialState, action) => {
  if (action.type === 'NOTIFICATIONS_GET') {
    if (action.state === 'REQUEST') {
      return assoc('loading', true, state);
    } else if (action.state === 'RESPONSE') {
      return pipe(
        assoc('loading', false),
        assoc('records', action.data.records),
      )(state);
    } else {
      return pipe(
        assoc('loading', false),
        assoc('errorMessage', action.error)
      )(state);
    }
  }
  if (action.type === 'NOTIFICATIONS_VIEWED') {
    const { notificationIds } = action;
    return assoc('records', state.records.map(notification => {
      if (!notificationIds.includes(notification.id)) {
        return notification;
      }
      return assoc('viewed', true)(notification);
    }))(state);
  }
  return state;
};

export default reducer;
