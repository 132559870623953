import React from 'react';
import { connect } from 'react-redux';
import {
  IonHeader,
  IonContent,
  IonItemGroup,
  IonItemDivider,
  IonItem,
  IonLabel,
  IonList,
  IonIcon,
  IonProgressBar,
  IonRefresher,
  IonRefresherContent,
  IonPage
} from '@ionic/react';
import './Attendees.css';

import Header from '../components/Header';
import VisitHeader from '../components/VisitHeader';
import NotFoundList from '../components/NotFoundList';

import { getContent } from '../features/content/actions';
import { selectContentPage } from '../features/content/selectors';

const pushContent = (history, id) => e => {
  e.preventDefault();
  history.push(`/content/content?contentId=${encodeURIComponent(id)}`);
};

const Content = ({content = {}, records = [], visit, visitLoading, history, doRefresh}) => (
  <IonPage>
    <IonHeader>
      <Header history={history} />
    </IonHeader>

    <IonContent color="light">
      { visitLoading && content.loading && <IonProgressBar type="indeterminate"></IonProgressBar> }

      <IonRefresher slot="fixed" onIonRefresh={doRefresh} color="light">
        <IonRefresherContent color="light"></IonRefresherContent>
      </IonRefresher>

      <VisitHeader visit={visit} />

      { records.length > 0 &&
        <IonList className="list content-list top-round attendee-list">
          <IonItemGroup>
            <IonItemDivider sticky>
              <IonLabel>
                Content
              </IonLabel>
            </IonItemDivider>

            { records.map((contentItem) => (
              <IonItem key={contentItem.id} button onClick={pushContent(history, contentItem.id)} lines="full">
                <IonIcon style={{marginLeft: '10px'}} src={`/icons/doctype/${contentItem.iconId}.svg`} slot="start"/>
                <IonLabel>
                  <h3>{contentItem.contentName}</h3>
                </IonLabel>
              </IonItem>
            ))}

          </IonItemGroup>
        </IonList>
      }
      { (!visitLoading && !content.loading && !content.errorMessage && records.length === 0) &&
          <NotFoundList iconSrc="/icons/icon-user.svg" title="No Content" description="There is no content for this visit."/>
      }
      { (!visitLoading && !content.loading && content.errorMessage) &&
          <NotFoundList iconSrc="/icons/icon-error.svg" title="Error loading content" description={content.errorMessage} />
      }
    </IonContent>
  </IonPage>
);

const mapDispatch = dispatch => {
  const doRefresh = (event) => {
    dispatch(getContent({disableCache: true})).then(() => {
      event.detail.complete();
    }).catch(() => {
      event.detail.complete();
    });
  };
  return {
    doRefresh
  };
};

export default connect(
  selectContentPage,
  mapDispatch
)(Content);
