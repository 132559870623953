import React from 'react';
import { connect } from 'react-redux';
import { pipe } from 'ramda';
import {
  IonHeader,
  IonButtons,
  IonContent,
  IonButton,
  IonIcon,
  IonProgressBar,
  IonRefresher,
  IonRefresherContent,
  IonSlide,
  IonPage
} from '@ionic/react';
import { chevronBack, chevronForward } from 'ionicons/icons';

import Header from '../components/Header';
import SlidesContainer from '../components/SlidesContainer';
import SlidesController from '../components/SlidesController';

import { getAgendaSessions } from '../features/agendasessions/actions';
import { selectFeedbackDetailPage } from '../features/feedback/selectors';
import './Details.css';


const FeedbackDetail = ({
  match,
  isLoading,
  records,
  doRefresh,
  gotoNext,
  gotoPrev,
  onSlideChange,
  currentIndex
}) => (
  <IonPage>
    <IonHeader>
      <Header match={match} visitBack={false} />
    </IonHeader>

    <IonContent color="light">
      { isLoading && <IonProgressBar type="indeterminate"></IonProgressBar> }
      <IonRefresher slot="fixed" onIonRefresh={doRefresh} color="light">
        <IonRefresherContent color="light"></IonRefresherContent>
      </IonRefresher>

      { records.length > 1 &&
          <SlidesContainer pager={false} id="feedback-slides" onSlideChange={onSlideChange} index={currentIndex}>
            { records.map( (feedback, i) => (
              <IonSlide key={feedback.agendaId} style={{width: '100vw', display: 'block', textAlign: 'left', color: 'black'}}>
                <div className="subheader-padding">
                  <h2>{feedback.title}</h2>
                  <ion-grid>
                    <ion-row justify-content-between>
                      <ion-col align-self-center>
                        { feedback.agendaId && <p className="dates">
                          {feedback.start} - {feedback.end}
                        </p> }
                      </ion-col>
                      <ion-col align-self-center>
                        <IonButtons>
                          <IonButton color="primary" fill="clear" onClick={gotoPrev} disabled={currentIndex <= 0}>
                            <IonIcon slot="icon-only" icon={chevronBack} />
                          </IonButton>
                          <IonButton color="primary" fill="clear" onClick={gotoNext} disabled={currentIndex >= records.length - 1}>
                            <IonIcon slot="icon-only" icon={chevronForward} />
                          </IonButton>
                        </IonButtons>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </div>

                <div className="list top-round details-content">
                  <h2 className="page-title"> Feedback form </h2>
                  { /* The iFrame doesn't work right now because the response header X-FRAME-OPTIONS: SAMEORIGIN. */ }
                  { i === currentIndex && feedback.type === 'visit' &&
                      <iframe title='visit survey' width='500px' height='300px' src={`https://visitops-demo.secure.force.com/visitops/VisitOps__CustomerPostSurvey?visitid=${feedback.visitId}&attendeeid=${feedback.attendeeId}`}></iframe>
                  }
                </div>
              </IonSlide> ))
            }
          </SlidesContainer>
      }
    </IonContent>
  </IonPage>
);

const mapDispatch = dispatch => {
  const doRefresh = (event) => {
    dispatch(getAgendaSessions({disableCache: true})).then(() => {
      event.detail.complete();
    });
  };
  return {
    doRefresh
  };
};

const onSlideChange = (index, props) => {
  props.history.replace(`/feedback/form?agendaId=${props.records[index].agendaId}`);
};

export default pipe(
  SlidesController(onSlideChange),
  connect(selectFeedbackDetailPage, mapDispatch)
)(FeedbackDetail);
