import { pathOr, pipe } from 'ramda';
import { selectVisit } from '../visits/selectors';
import { isPlatform } from '@ionic/core';
import queryString from 'qs';

const defaultObject = {locationMap: {}};

const mapsPlatform = isPlatform(window, 'ios') ? 'https://maps.apple.com/?q=' : 'https://maps.google.com/?q=';

const mapKey = 'AIzaSyBmNaHZ2tpfJdpzzkfEJconMsTdrskgFAE';
const makeMapUrl = ({longitude, latitude} = {}) => {
  const params = queryString.stringify({
    key: mapKey,
    zoom: 16,
    size: '300x300',
    markers: `color:Marker_Color|label:Marker_Label|${latitude},${longitude}`
  });
  return `https://maps.googleapis.com/maps/api/staticmap?${params}`;
};

export const selectLocationModal = state => {
  const visit = selectVisit(state);
  return {
    visit,
    location: pipe(
      pathOr(defaultObject, ['locations', visit.id, 'record']),
      location => ({
        ...location,
        mapImageSrc: makeMapUrl(location),
        mapQuery: `${mapsPlatform}${location.locationStreetAddress} ${location.locationCity}, ${location.locationStateOrProvince} ${location.locationPostalCodeOrZip} ${location.locationCountry}`
      })
    )(state),
    isLoading: pathOr(false, ['locations', visit.id, 'loading'], state)
  };
};
