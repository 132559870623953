import { curry, assocPath, path, assoc } from 'ramda';
export const mapPath = curry((_path, f, obj) =>
  assocPath(_path, f(path(_path, obj)), obj)
);

export const spy = m => o => {
  console.log(m, o);
  return o;
};

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const updateStateObjFromInput = setState => prop => e => {
  e.preventDefault();
  setState(assoc(prop, e.target.value));
};

// Thanks Stack Overflow: https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
export const humanFileSize = bytes => {
  const thresh = 1000;
  if(Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }
  const units = ['kB','MB','GB','TB','PB','EB','ZB','YB'];
  let u = -1;
  do {
    bytes /= thresh;
    u = u + 1;
  } while(Math.abs(bytes) >= thresh && u < units.length - 1);
  return bytes.toFixed(1) + ' ' + units[u];
};
