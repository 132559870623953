import { pipe, assocPath } from 'ramda';
const initialState = { };


const reducer = (state = initialState, action) => {
  if (action.type === 'VISIT_SELECT') {
    const { visit, visitId } = action.payload;
    return assocPath([visitId, 'visit', 'data'], visit, state);
  }
  if(action.type === 'VISIT_GET'){
    const visitId = action.metadata.visitId;
    if(action.state === 'REQUEST'){
      return pipe(
        assocPath([visitId, 'visit', 'loading'], true),
        assocPath([visitId, 'visit', 'errorMessage'], '')
      )(state);
    } else if(action.state === 'RESPONSE') {
      return pipe(
        assocPath([visitId, 'visit', 'loading'], false),
        assocPath([visitId, 'visit', 'cachedAt'], new Date()),
        assocPath([visitId, 'visit', 'data'],  action.data)
      )(state);
    } else {
      return pipe(
        assocPath([visitId, 'visit', 'loading'], false),
        assocPath([visitId, 'visit', 'errorMessage'], action.error),
      )(state);
    }
  }
  if(action.type === 'AGENDA_SESSIONS_GET'){
    const visitId = action.metadata.visitId;
    if(action.state === 'REQUEST'){
      return pipe(
        assocPath([visitId, 'sessions', 'loading'], true),
        assocPath([visitId, 'sessions', 'errorMessage'], '')
      )(state);
    } else if(action.state === 'RESPONSE') {
      return pipe(
        assocPath([visitId, 'sessions', 'loading'], false),
        assocPath([visitId, 'sessions', 'cachedAt'], new Date()),
        assocPath([visitId, 'sessions', 'records'],  action.data)
      )(state);
    } else {
      return pipe(
        assocPath([visitId, 'sessions', 'loading'], false),
        assocPath([visitId, 'sessions', 'errorMessage'], action.error),
      )(state);
    }
  }
  return state;
};

export default reducer;
