import React, {useState, useCallback} from 'react';
import { connect } from 'react-redux';
import {
  IonContent,
  IonHeader,
  IonProgressBar,
  IonList,
  IonItemGroup,
  IonItemDivider,
  IonLabel,
  IonPage
} from '@ionic/react';
import { selectFeedbackSinglePage } from '../features/feedback/selectors';

import './Schedule.css';

import Header from '../components/Header';
import VisitHeader from '../components/SmallVisitHeader';


function useClientRect() {
  const [rect, setRect] = useState(null);
  const ref = useCallback(node => {
    if (node !== null) {
      console.log(node.getBoundingClientRect());
      setRect(node.getBoundingClientRect());
    }
  }, []);
  return [rect, ref];
}

const FeedbackPage = ({sessions, history, visit, surveyUrl}) => {
  const [rect, ref] = useClientRect(/* surveyUrl - needs tested once feedback is fixed */);
  return (
    <IonPage>
      <IonHeader color="light">
        <Header history={history} />
      </IonHeader>

      <IonContent color="light">
        { sessions.loading && <IonProgressBar type="indeterminate"></IonProgressBar> }
        <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>

          <VisitHeader visit={visit} />

          <div ref={ref} className="list top-round attendee-list" style={{flexGrow: '1'}}>
            <IonList>
              <IonItemGroup>
                <IonItemDivider>
                  <IonLabel>Feedback</IonLabel>
                </IonItemDivider>

                <div>
                  {rect && surveyUrl &&
                    <iframe frameBorder="0" style={{border: 'none'}} title='visit survey' width={rect.width + 'px'} height={(rect.height - 45) + 'px'} src={surveyUrl}></iframe>
                  }
                </div>

              </IonItemGroup>
            </IonList>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};


export default connect(
  selectFeedbackSinglePage
)(FeedbackPage);
