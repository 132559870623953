import React from 'react';
import { connect } from 'react-redux';
import {
  IonHeader,
  IonContent,
  IonPage,
  IonList,
  IonItem,
  IonLabel,
  IonAvatar
} from '@ionic/react';
import './Details.css';

import Header from '../components/Header';
import VisitHeader from '../components/VisitHeader';
import { selectDetailPage } from '../features/visits/selectors.js';

const ImportantContact = ({importantContact: {name, title, email, phone, image}}) => (
  <>
    <h1 className="welcome">Important Contacts</h1>
    <IonList style={{border: 'none'}}>
      <IonItem>
        { image && <IonAvatar slot="start">
          <img src={image} alt="Important contact"/>
        </IonAvatar> }
        <IonLabel className="ion-text-wrap">
          <h2>{name || 'Contact'}</h2>
          {title && <h3>{title}</h3>}
          {email && <p><a href={`mailto:${email}`}>{email}</a></p>}
          {phone && <p><a href={`tel:${phone}`}>{phone}</a></p>}
        </IonLabel>
      </IonItem>
    </IonList>
  </>
);

const Details = ({visit, loading, history}) => (
  <IonPage>
    <IonHeader>
      <Header history={history} />
    </IonHeader>

    <IonContent className="details-content" color="light">
      <VisitHeader visit={visit} />

      { !loading &&
        <div className="list top-round details-content">
          <h2 className="page-title">Details</h2>
          <h1 className="welcome">{visit.detailsHeader1}</h1>
          { visit.detailsLogo && <img src={visit.detailsLogo} alt="company logo" /> }
          <h3 className="subheader">{visit.detailsSubheader}</h3>
          { visit.importantContact && <ImportantContact importantContact={visit.importantContact} /> }
        </div>
      }
    </IonContent>
  </IonPage>
);

export default connect(
  selectDetailPage
)(Details);

