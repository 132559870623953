import { pipe, assocPath } from 'ramda';
const initialState = { };

const reducer = (state = initialState, action) => {
  if(action.type === 'CONTENT_GET'){
    const visitId = action.metadata.visitId;
    if(action.state === 'REQUEST'){
      return pipe(
        assocPath([visitId, 'loading'], true ),
        assocPath([visitId, 'errorMessage'], '')
      )(state);
    } else if(action.state === 'RESPONSE') {
      return pipe(
        assocPath([visitId, 'cachedAt'], new Date()),
        assocPath([visitId, 'loading'], false),
        assocPath([visitId, 'records'], action.data)
      )(state);
    } else {
      return pipe(
        assocPath([visitId, 'loading'], false),
        assocPath([visitId, 'errorMessage'], action.error)
      )(state);
    }
  }
  return state;
};

export default reducer;
