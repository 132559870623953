import React  from 'react';
import { format } from 'date-fns';

const formatDate = date => date && format(new Date(date), 'MMM d');

const SmallVisitHeader = ({visit}) => {
  return (
    <>
      <div className="subheader-padding">
        <h6>{visit.visitName}</h6>
        <p className="dates">
          <span className="dates">
            {formatDate(visit.arrivalDateTimeLocationFormula)} - {formatDate(visit.departureDateTimeLocationFormula)}
          </span>
          &nbsp;
          &nbsp;
          <span className="location">
            {visit.locationNameFormula}
          </span>
        </p>
      </div>
    </>
  );
};

export default SmallVisitHeader;
