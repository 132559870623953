import React from 'react';
import {
  IonItem,
  IonNote,
} from '@ionic/react';

const ErrorMessage = ({
  errorMessage
}) => {
  return errorMessage ? (
    <IonItem>
      <IonNote color="danger">{errorMessage}</IonNote>
    </IonItem>
  ) : null;
};

export default ErrorMessage;
