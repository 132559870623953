import { api } from '../../services/api.js';
import { remoteAction } from '../../services/actionService.js';

export const getVisits = (limit, skip, filter) => {
  const visitQueryParams = {
    limit,
    skip,
    departureDateTime: filter,
  };
  const action = () => api.get('/visits', visitQueryParams).then((res) => ({
    records: res.data,
    total: res.headers['x-total-count'],
    filter,
  }));
  return remoteAction({
    type: 'VISITS_GET',
    action
  });
};

export const getVisit = ({visitId}) => dispatch => {
  const action = () => api.get(`/visits/${visitId}`).then(({data}) => data);
  return dispatch(remoteAction({
    type: 'VISIT_GET',
    action,
    metadata: { visitId }
  })).catch(e => {
    console.log('error getting visit ' + visitId);
    console.error(e);
  });
};

export const setVisitsFilter = (filter = 'upcoming') => {
  return {
    type: 'VISITS_SET_FILTER',
    payload: filter
  };
};

export const gotoVisit = ({visitId, push}) => (dispatch, getStore) => {
  localStorage.visitId = visitId;
  const visit = getStore().visits.records.find(({id}) => id === visitId) || {};
  dispatch({
    type: 'VISIT_SELECT',
    payload: { visitId, visit }
  });
  setTimeout(() => {
    push('/details');
  });
};
