import React from 'react';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { IonApp, IonPage,  setupConfig } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import '@ionic/core/css/core.css';
import '@ionic/core/css/ionic.bundle.css';
import './custom-scripts/theme.css';
import './style.css';

import LoginPage from './pages/Login';
import MobileAppLinksPage from './pages/MobileAppLinks';
import MenuContainer from './components/MenuContainer';
import rootReducer from './services/rootReducer.js';
import { routeApiMiddleware } from './services/apiMiddleware.js';
import RouterRedux from './components/RouterRedux.js';

setupConfig({
  mode: 'ios'
});


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer({}),
  composeEnhancers(
    applyMiddleware(
      thunk,
      routeApiMiddleware
    ),
  ),
);

const HistoryConsumer = ({history, push}) => (
  <RouterRedux history={history} push={push}>
    <div id="app">
      <IonApp>
        <IonPage>
          <Switch>
            <Route path="/login" component={LoginPage} />
            <Route path="/mobile-app-links" component={MobileAppLinksPage} />
            <Route path="/" component={MenuContainer} />
          </Switch>
        </IonPage>
      </IonApp>
    </div>
  </RouterRedux>
);

const App = () => (
  <Provider store={store}>
    <IonReactRouter>
      <Switch>
        <Route path="/" component={HistoryConsumer} />
      </Switch>
    </IonReactRouter>
  </Provider>
);

export default App;
