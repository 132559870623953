// Move to backend?  - this just increases the bundle size for no good reason...
import { find } from 'ramda';
import { humanFileSize } from '../../utils/utils';

const contentTypes = [
  {
    fileTypes: ['mp3', 'wav'],
    iconId: 'audio',
    fileTypeLongName: 'Audio File',
  },
  {
    fileTypes: ['csv'],
    iconId: 'csv',
    fileTypeLongName: 'Spreadsheet',
  },
  {
    fileTypes: ['xls', 'xlsx'],
    iconId: 'excel',
    fileTypeLongName: 'Microsoft Excel',
  },
  {
    fileTypes: ['html', 'htm'],
    iconId: 'html',
    fileTypeLongName: 'HTML Document',
  },
  {
    fileTypes: ['ppt', 'pptx'],
    iconId: 'ppt',
    fileTypeLongName: 'Microsoft Powerpoint',
  },
  {
    fileTypes: ['doc', 'docx'],
    iconId: 'word',
    fileTypeLongName: 'Microsoft Word Document',
  },
  {
    fileTypes: ['pdf'],
    iconId: 'pdf',
    fileTypeLongName: 'Adobe PDF',
  },
  {
    fileTypes: ['txt'],
    iconId: 'txt',
    fileTypeLongName: 'Text File',
  },
  {
    fileTypes: ['jpg', 'png', 'gif'],
    iconId: 'image',
    fileTypeLongName: 'Image',
  },
  {
    fileTypes: ['zip'],
    iconId: 'zip',
    fileTypeLongName: 'Zip File',
  },
];

export const mapContentRecord = record => {
  const fileData = find(type => type.fileTypes.includes(record.fileType))(contentTypes) || {};
  return {
    ...record,
    iconId: fileData.iconId || 'unkown',
    fileTypeLongName: fileData.fileTypeLongName || 'Unknown File Type',
    prettyFileSize: humanFileSize(record.fileSize),
  };
};
