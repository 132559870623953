import { connect } from 'react-redux';
import React, { useState } from 'react';
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonContent,
  IonTitle,
  IonModal,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonNote
} from '@ionic/react';
import { assoc } from 'ramda';

import Input from '../components/Input';
import { submitChangePassword } from '../features/auth/actions';

const updateStateObjFromInput = setState => prop => e => {
  e.preventDefault();
  setState(assoc(prop, e.target.value));
};

const AccountModal = ({isModalOpen, hideModal, isLoading, errorMessage, submitChangePassword}) => {
  const [changePasswordState, setChangePasswordState] = useState({});
  const updateChangePasswordInput = updateStateObjFromInput(setChangePasswordState);
  const submitChangePasswordForm = e => {
    e.preventDefault();
    submitChangePassword(changePasswordState).then(() => {
      hideModal();
    }).catch(err => {
      console.error(err);
    });
  };

  return (
    <IonModal isOpen={isModalOpen} onIonModalDidDismiss={() => console.log('model dismissed', isModalOpen)}>
      <IonHeader>
        <IonToolbar color="light">
          <IonTitle>Account Settings</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => hideModal()}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent color="light">
        <form onSubmit={submitChangePasswordForm}>
          <IonList>
            <IonItem lines="full">
              <IonLabel>
                Reset Password
              </IonLabel>
            </IonItem>
            <Input type="password" prop="oldPassword" state={changePasswordState} onChange={updateChangePasswordInput} title="Old Password"/>
            <Input type="password" prop="newPassword" state={changePasswordState} onChange={updateChangePasswordInput} title="New Password"/>
            <Input type="password" prop="newPasswordConfirmed" state={changePasswordState} onChange={updateChangePasswordInput} title="Confirm New Password"/>
            { errorMessage &&
              <IonItem>
                <IonNote color="danger">{errorMessage}</IonNote>
              </IonItem>
            }
          </IonList>
          <IonButton expand="block" type="submit" id='reset-password' disabled={isLoading === true}>Reset</IonButton>
        </form>
      </IonContent>
    </IonModal>
  );
};

export default connect(
  state => ({
    isLoading: state.auth.loading,
    errorMessage: state.auth.errorMessage
  }),
  dispatch => ({
    submitChangePassword: formData => dispatch(submitChangePassword(formData))
  })
)(AccountModal);
