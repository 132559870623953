import { groupBy, merge, toPairs, filter, prop, path, propOr, pathOr, map, pipe, defaultTo, propEq, find, evolve } from 'ramda';
import { mapContentRecord } from '../content/helpers';
import { selectVisit } from '../visits/selectors';
import { format } from 'date-fns';
import qs from 'qs';


const defaultArray = [];

const defaultObject = {
  records: defaultArray
};

export const selectSessions = state => {
  const visitId = state.global.visitId;

  const container = pathOr(defaultObject, ['visitDetails', visitId, 'sessions'], state);

  return container;
};

const selectAgendaSessions = container => pipe(
  propOr(defaultArray, 'records'),
  filter(propEq('showOnCustomerAgenda', true)),
  map(evolve({
    visit_contents: map(mapContentRecord)
  }))
)(container);


const defaultSession = {
  presenters: []
};

export const loadingSessions = pipe(
  selectSessions,
  pathOr(false, ['loading'])
);


export const selectSession = (sessionId, state) => {
  return pipe(
    selectSessions,
    pathOr(defaultArray, ['records']),
    find(propEq('id', sessionId)),
    defaultTo(defaultSession)
  )(state);
};


export const selectAgendaContentPage = (sessionId, state) => {
  const visit = selectVisit(state);
  const isLoading = loadingSessions(state);
  const session = selectSession(sessionId, state);

  const records = pipe(
    propOr(defaultArray, 'visit_contents'),
    map(mapContentRecord)
  )(session);

  const defaultIndex = pipe(
    pathOr('', ['router', 'location', 'search']),
    s => qs.parse(s, {ignoreQueryPrefix: true}),
    prop('agendaContentId'),
    id => records.findIndex(propEq('id', id)),
    id => id === -1 ? 0 : id
  )(state);
  return {
    defaultIndex,
    session,
    records,
    visit,
    isLoading
  };
};

export const selectPresenterPage = (sessionId, state) => {
  const visit = selectVisit(state);
  const isLoading = loadingSessions(state);
  const session = selectSession(sessionId, state);
  const records = session.presenters;
  const defaultIndex = pipe(
    pathOr('', ['router', 'location', 'search']),
    s => qs.parse(s, {ignoreQueryPrefix: true}),
    prop('presenterId'),
    id => records.findIndex(propEq('id', id)),
    id => id === -1 ? 0 : id
  )(state);
  return {
    defaultIndex,
    session,
    records,
    visit,
    isLoading
  };
};


export const selectAgendaPage = (state) => {
  const visit = selectVisit(state);
  const container = selectSessions(state);
  const records = selectAgendaSessions(container);

  const defaultIndex = pipe(
    pathOr('', ['router', 'location', 'search']),
    s => qs.parse(s, {ignoreQueryPrefix: true}),
    prop('sessionId'),
    id => id || path(['global', 'agendaId'], state),
    id => records.findIndex(propEq('id', id)),
    id => id === -1 ? 0 : id
  )(state);

  return {
    sessions: container,
    records,
    visit,
    defaultIndex
  };
};

const groupItems = (items = []) => {
  return pipe(
    map(session => merge(session, { day: format(new Date(session.startTimeLocationFormula), 'MMM do, yyyy')})),
    groupBy(prop('day')),
    toPairs
  )(items);
};

export const selectAgendaListPage = (state) => {
  const visit = selectVisit(state);
  const container = selectSessions(state);
  const records = selectAgendaSessions(container);
  const groupedItems = groupItems(records);

  return {
    sessions: container,
    groupedItems,
    records,
    visit
  };
};
