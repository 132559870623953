import React from 'react';
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonContent,
  IonTitle,
  IonButton,
  IonModal
} from '@ionic/react';
import HelpText from '../custom-scripts/components/HelpText';

const HelpModal = ({isModalOpen, hideModal}) => (
  <IonModal isOpen={isModalOpen}>
    <IonHeader>
      <IonToolbar color="light">
        <IonTitle>Help</IonTitle>
        <IonButtons slot="end">
          <IonButton onClick={hideModal}>Close</IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>

    <IonContent color="light" class="menu-modal-content">
      <HelpText />
    </IonContent>
  </IonModal>
);

export default HelpModal;
