import { Plugins } from '@capacitor/core';
import { api } from '../../services/api.js';
import { remoteAction } from '../../services/actionService.js';
const { PushNotifications, Device } = Plugins;


function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');
  const rawData = window.atob(base64);
  return Uint8Array.from([...rawData].map((char) => char.charCodeAt(0)));
}


// I followed these docs:
// https://developers.google.com/web/fundamentals/push-notifications
const subscribeToWebNotification = () => {
  console.log('sub to web push');
  if ('serviceWorker' in navigator) {
    console.log('subscribe to service worker messages');
    navigator.serviceWorker.addEventListener('message', ({ data }) => {
      if (!data) {
        return;
      }
      if (data.event === 'notificationclick') {
        api.post('/user_notifications', { notificationIds: [data.notificationId] });
      }
    });
    console.log('waiting for service worker...');
    return navigator.serviceWorker.ready.then((reg) => {
      console.log('service worker ready');
      return reg.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(process.env.REACT_APP_WEB_PUSH_PUBLIC_KEY)
      }).then((sub) => {
        //Need to do this to get the text encoding of the keys in the subscriptions
        const basicSub = JSON.parse(JSON.stringify(sub));
        const body = {
          token: basicSub.endpoint,
          platform: 'web',
          webKeys: basicSub.keys
        };
        return body;
      }).catch((e) => {
        if (Notification.permission === 'denied') {
          console.warn('Permission for notifications was denied');
        } else {
          console.error('Unable to subscribe to push', e);
        }
        throw e;
      });
    });
  }
  console.log('no service worker');
  return Promise.resolve();
};

const registerPromise = () => new Promise((res, rej) => {
  PushNotifications.register().catch(rej);

  PushNotifications.addListener('registration', token => res(token.value));

  PushNotifications.addListener('registrationError', rej);

  PushNotifications.addListener('pushNotificationActionPerformed', ({ notification }) => {
    if (!notification.data || !notification.data.notificationId) {
      console.log('Notification click event missing notification id. Unable to mark as viewed.');
      return;
    }
    api.post('/user_notifications', { notificationIds: [notification.data.notificationId] });
  });
}).then(token => {
  return Device.getInfo().then(({platform}) => {
    return {
      token,
      platform
    };
  });
}).catch( e => {
  console.error(e);
  console.log('Native push notifications failed, try web');
  return subscribeToWebNotification();
});

const saveToken = ({token, platform, webKeys}) => {
  return api.put('/push_tokens', {
    token,
    platform,
    webKeys,
    isValid: true
  });
};

export const registerPushToken = () => (dispatch, getStore) => {
  const { hasRegistered } = getStore().notifications;
  if(hasRegistered){
    return;
  }
  return dispatch(remoteAction({
    type: 'REGISTER_TOKEN',
    action: registerPromise
  })).then( body => {
    dispatch(remoteAction({
      type: 'REGISTER_TOKEN_SAVE',
      action: () => saveToken(body)
    }));
  }).catch( e => {
    //This should not throw and cause an issue with logging in
    console.log('error registering push notifications.  Catch error and do not proagate so user can use app.');
    console.error(e);
  });
};

export const getNotifications = (limit = 25) => {
  const action = () => api.get(`/notifications?limit=${limit}`).then(({ data }) => ({ records: data }));
  return remoteAction({
    type: 'NOTIFICATIONS_GET',
    action
  });
};

export const setNotificationsAsViewed = notificationIds => dispatch => {
  api.post('/user_notifications', { notificationIds });
  return dispatch({
    type: 'NOTIFICATIONS_VIEWED',
    notificationIds
  });
};
