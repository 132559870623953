import { Storage } from 'aws-amplify';

import { getCurrentUser, updateUser } from '../auth/actions';
import { remoteAction } from '../../services/actionService.js';

import { profilePictureName } from '../../consts';


export const changeProfileImage = ({ event }) => dispatch => {
  if (!event.target.files.length) {
    return Promise.resolve();
  }
  const file = event.target.files[0];
  const action = () => Storage.put(profilePictureName, file, {
    level: 'protected',
    contentType: 'image/png'
  });

  return dispatch(remoteAction({
    type: 'CHANGE_PROFILE_IMAGE',
    action
  })).then(() => {
    return dispatch(updateUser({profilePictureName}));
  }).then(() => {
    return dispatch(getCurrentUser);
  });
};
