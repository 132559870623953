import qs from 'qs';
import { pathOr, pipe, prop, propEq } from 'ramda';
import { selectVisit } from '../visits/selectors';

const defaultObject = { records: [] };

const selectSessions = (visitId, state) => {
  return pathOr(defaultObject, ['feedback', visitId], state);
};

//This is for MVP
export const selectFeedbackSinglePage = (state) => {
  const visit = selectVisit(state);
  const visitId = visit.id;
  const container = selectSessions(visitId, state);
  const records = container.records || [];
  const feedback = records.find(propEq('type', 'visit')) || defaultObject;

  // if the base survey url is not set in the environment
  // set the survey url to undefined so that the iFrame is
  // not loaded in the component
  const surveyUrl = process.env.REACT_APP_SURVEY_BASE_URL && `${process.env.REACT_APP_SURVEY_BASE_URL}/VisitOps__CustomerPostSurvey?visitid=${feedback.visitId}&attendeeid=${feedback.attendeeId}`;
  return {
    sessions: container,
    visit,
    surveyUrl
  };
};


// These we'll use on the final version
// when each session has it's own iframe
// Not called now - should be tree shaked out by webpack
export const selectFeedbackPage = (state) => {
  const visit = selectVisit(state);
  const visitId = visit.id;
  const container = selectSessions(visitId, state);
  const records = container.records || [];
  return {
    sessions: container,
    records,
    visit
  };
};

export const selectFeedbackDetailPage = (state) => {
  const visit = selectVisit(state);
  const visitId = visit.id;
  const container = selectSessions(visitId, state);
  const isLoading = container.loading;

  const records = container.records || [];

  const defaultIndex = pipe(
    pathOr('', ['router', 'location', 'search']),
    s => qs.parse(s, {ignoreQueryPrefix: true}),
    prop('agendaId'),
    id => records.findIndex(propEq('agendaId', id)),
    id => id === -1 ? 0 : id
  )(state);
  return {
    isLoading,
    records,
    visit,
    defaultIndex
  };
};
