import { pipe, assocPath } from 'ramda';
const initialState = { };

const reducer = (state = initialState, action) => {
  if(action.type === 'LOCATION_GET'){
    const visitId = action.metadata.visitId;
    if(action.state === 'REQUEST'){
      return assocPath([visitId, 'loading'], true, state);
    } else if(action.state === 'RESPONSE') {
      return pipe(
        assocPath([visitId, 'cachedAt'], action.date),
        assocPath([visitId, 'loading'], false),
        assocPath([visitId ,'record'], action.data)
      )(state);
    } else {
      return pipe(
        assocPath([visitId, 'loading'], false),
        assocPath([visitId, 'errorMessage'], action.error)
      )(state);
    }
  }
  return state;
};

export default reducer;
