import React from 'react';
import {
  IonHeader,
  IonButtons,
  IonContent,
  IonItem,
  IonButton,
  IonIcon,
  IonProgressBar,
  IonRow,
  IonLabel,
  IonRefresher,
  IonRefresherContent,
  IonSlide,
  IonPage
} from '@ionic/react';
import { chevronBack, chevronForward } from 'ionicons/icons';
import './ContentItemDetail.css';


import SlidesContainer from './SlidesContainer';
import Header from './Header';

const ContentItemDetail = ({
  match,
  isLoading,
  records,
  doRefresh,
  gotoNext,
  gotoPrev,
  onSlideChange,
  currentIndex
}) => {
  return (
    <IonPage>
      <IonHeader>
        <Header match={match} visitBack={false} />
      </IonHeader>

      <IonContent color="light agenda-session">
        { isLoading && <IonProgressBar type="indeterminate"></IonProgressBar> }
        <IonRefresher slot="fixed" onIonRefresh={doRefresh} color="light">
          <IonRefresherContent color="light"></IonRefresherContent>
        </IonRefresher>

        { records.length > 0 &&
            <SlidesContainer pager={false} onSlideChange={onSlideChange} index={currentIndex}>
              { records.map( contentItem => (
                <IonSlide key={contentItem.id} style={{width: '100vw', display: 'block', textAlign: 'left', color: 'black'}}>
                  <div className="subheader-padding">
                    <ion-grid>
                      <IonRow style={{ 'paddingBottom': '14px' }}>
                        <h2>{contentItem.contentName}</h2>
                      </IonRow>
                      <IonRow justify-content-between>
                        <ion-col align-self-center>
                          <p className="location">{contentItem.fileTypeLongName}</p>
                          <p className="location">{contentItem.prettyFileSize}</p>
                        </ion-col>
                        <ion-col align-self-center>
                          <IonButtons>
                            <IonButton color="primary" fill="clear" onClick={gotoPrev} disabled={currentIndex <= 0}>
                              <IonIcon slot="icon-only" icon={chevronBack} />
                            </IonButton>
                            <IonButton color="primary" fill="clear" onClick={gotoNext} disabled={currentIndex >= records.length - 1}>
                              <IonIcon slot="icon-only" icon={chevronForward} />
                            </IonButton>
                          </IonButtons>
                        </ion-col>
                      </IonRow>
                    </ion-grid>
                  </div>
                  <div className="content-preview">
                    <img src={contentItem.previewFileUrl} alt="preview" download={true} />
                  </div>
                  <div className="content-list">
                    <IonItem lines="full">
                      <IonIcon style={{marginLeft: '8px'}} slot="start" size="large" src={`/icons/doctype/${contentItem.iconId}.svg`} color="primary"></IonIcon>
                      <IonLabel>
                        <h3>Download file</h3>
                      </IonLabel>
                      { contentItem.fileUrl &&
                      <a style={{padding: '10px'}} slot="end" rel="noopener noreferrer" target="_blank" href={contentItem.fileUrl}>
                        <IonIcon src="/icons/icon-download.svg"></IonIcon>
                      </a> }
                    </IonItem>
                  </div>
                </IonSlide> ))
              }
            </SlidesContainer>
        }
      </IonContent>
    </IonPage>
  );
};

export default ContentItemDetail;
