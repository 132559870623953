import { evolve, propEq } from 'ramda';
const initialState = {
  records: []
};

const reducer = (state = initialState, action) => {
  if(action.type === 'TOAST_ADD'){
    return evolve({
      records: r => r.concat([action.payload])
    }, state);
  }
  if (action.type === 'TOAST_REMOVE') {
    return evolve({
      records: r => r.filter(propEq('id', action.payload.id))
    }, state);
  }
  return state;
};

export default reducer;
