import React, { useState } from 'react';
import { connect } from 'react-redux';
import { pathOr, assoc } from 'ramda';
import {
  IonMenu,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonAvatar,
  IonItemDivider,
  IonItem,
  IonLabel,
  IonMenuToggle,
  IonList
} from '@ionic/react';

import AccountModal from '../modals/AccountModal';
import ProfileModal from '../modals/ProfileModal';
import HelpModal from '../modals/HelpModal';
import TermsOfService from '../modals/TermsOfService';
import PrivacyPolicy from '../modals/PrivacyPolicy';

const routes = {
  visitPages: [
    { title: 'Date', path: '/visits?groupBy=date' },
    { title: 'Location', path: '/visits?groupBy=location'},
  ],
  accountPages: [
    { title: 'Attendee Profile', Modal: ProfileModal },
    { id: 'account-settings', title: 'Password Reset', Modal: AccountModal },
  ],
  supportPages: [
    { title: 'Help', Modal: HelpModal },
    { title: 'Terms of Service', Modal: TermsOfService},
    { title: 'Privacy Policy', Modal: PrivacyPolicy },
  ],
  logoutPages: [
    { title: 'Logout', path: '/logout' }
  ]
};


const handleClick = ({history, title, path, setState}) => (e) => {
  e.preventDefault();
  if(path){
    return history.push(path);
  }
  setState(assoc(title, false));
  setTimeout(() => {
    setState(assoc(title, true));
  });
};


const renderlistItems = ({list, history, state, setState}) => {
  return list.map(({id, title = '', path = '', Modal}) => (
    <IonMenuToggle key={title} auto-hide="false">
      <IonItem id={id || title.replace(' ', '-')} className="list-item-set-cursor" onClick={handleClick({history, title, path, setState})} lines="none">
        <IonLabel>
          {title}
        </IonLabel>
      </IonItem>
      { Modal && <Modal isModalOpen={state[title]} hideModal={() => setState(assoc(title, false))} />  }
    </IonMenuToggle>
  ));
};

const Menu = ({ history, user }) => {
  const [state, setState] = useState({});
  return (
    <IonMenu contentId="main" side="end">
      <IonHeader>
        <IonToolbar>
          <IonTitle>&nbsp;</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent class="outer-content slide-menu">
        <IonList>
          <IonItemDivider color="dark">
            <IonLabel position="stacked">
              Group Visits By
            </IonLabel>
          </IonItemDivider>
          { renderlistItems({list: routes.visitPages, history}) }
        </IonList>

        <IonList>
          <IonItem lines="none">
            <IonAvatar slot="start">
              <img src={pathOr('/user.png', ['picture'], user)} alt="your avatar"/>
            </IonAvatar>
            <IonLabel>
              <h2>{(user.given_name || '') + ' ' + (user.family_name || '')}</h2>
              <p>{user.email || 'cil@visitops.demo.com'}</p>
            </IonLabel>
          </IonItem>
          { renderlistItems({list: routes.accountPages, history, state, setState}) }
        </IonList>

        <IonList>
          { renderlistItems({list: routes.supportPages, history, state, setState}) }
        </IonList>

        <IonList className="last">
          { renderlistItems({list: routes.logoutPages, history, state, setState}) }
        </IonList>

      </IonContent>
    </IonMenu>
  );
};


const mapStateToProps = state => {
  return {
    user: state.global.user
  };
};

export default connect(
  mapStateToProps
)(Menu);
