import qs from 'qs';
import { pathOr, propEq, pipe, prop, map } from 'ramda';
import { selectVisit, selectVisitLoading } from '../visits/selectors';
import { mapContentRecord } from './helpers';

// Because js is not immutable and is referenced based...
// Save from making a new object every render...
const defaultObject = {};
const defaultArray = [];

export const selectContentPage = (state) => {
  const visit = selectVisit(state);
  const container = state.content[visit.id] || defaultObject;
  const records = container.records || defaultArray;

  return {
    content: container,
    visitLoading: selectVisitLoading(state),
    records: records.map(mapContentRecord),
    visit
  };
};

export const selectContentItemDetailPage = (state) => {
  const visit = selectVisit(state);

  const records = pipe(
    pathOr(defaultArray, ['content', visit.id, 'records']),
    map(mapContentRecord)
  )(state);

  const defaultIndex = pipe(
    pathOr('', ['router', 'location', 'search']),
    s => qs.parse(s, {ignoreQueryPrefix: true}),
    prop('contentId'),
    id => records.findIndex(propEq('id', id)),
    id => id === -1 ? 0 : id
  )(state);

  return {
    isLoading: pathOr(false, ['content', visit.Id, 'loading'], state),
    records,
    defaultIndex,
    visit
  };
};
