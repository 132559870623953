import { assoc, pipe } from 'ramda';
import { filterItems } from './selectors';

const initialState = {
  loading: false,
  records: [],
  errorMessage: '',
  filter: 'upcoming',
  upcomingTotalCount: null,
  pastTotalCount: null,
};

const reducer = (state = initialState, action) => {
  if(action.type === 'VISITS_GET'){
    if(action.state === 'REQUEST'){
      return assoc('loading', true, state);
    } else if(action.state === 'RESPONSE') {
      let totalCount = action.data.total;
      // if API returns zero records, make sure we stop trying to fetch
      if (action.data.records.length === 0) {
        totalCount = filterItems(action.data.filter)(state.records).length;
      }
      return pipe(
        assoc('loading', false),
        assoc('records', state.records.concat(action.data.records)),
        assoc(`${action.data.filter}TotalCount`, totalCount)
      )(state);
    } else {
      return pipe(
        assoc('loading', false),
        assoc('errorMessage', action.error)
      )(state);
    }
  }
  if (action.type === 'VISITS_SET_FILTER') {
    return assoc('filter', action.payload, state);
  }
  return state;
};

export default reducer;


