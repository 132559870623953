import { connect } from 'react-redux';
import React, { useState } from 'react';
import {
  IonButton,
  IonButtons,
  IonIcon,
  IonSpinner,
  //IonActionSheet,
  IonModal
} from '@ionic/react';
import { format } from 'date-fns';
import { assoc } from 'ramda';

import Location from '../modals/Location';
import { getLocation } from '../features/locations/actions';

const formatDate = date => format(new Date(date), 'MMM d');

const VisitHeader = ({visit, getLocation}) => {
  const [ state, setState ] = useState({showModal: false, showActionSheet: false});
  const showModal = () => {
    getLocation();
    setState(assoc('showModal', false));
    setTimeout(() => {
      setState(assoc('showModal', true));
    }, 50);
  };
  const hideModal = () => {
    setState(assoc('showModal', false));
  };

  if (!visit.name) {
    return (
      <div className="not-found-list">
        <IonSpinner />
      </div>
    );
  }

  return (
    <>
      <IonModal isOpen={state.showModal}>
        <Location hide={hideModal} />
      </IonModal>
      <div className="subheader-padding">
        <h2 className="visit-title">{visit.visitName}</h2>
        <ion-grid>
          <ion-row justify-content-between>
            <ion-col>
              <p className="dates">
                {formatDate(visit.arrivalDateTimeLocationFormula)} - {formatDate(visit.departureDateTimeLocationFormula)}
              </p>
              <p className="location">{visit.locationNameFormula}</p>
            </ion-col>
            <ion-col>
              <IonButtons>
                <IonButton className="location-button" color="medium" fill="clear" onClick={showModal}>
                  <IonIcon slot="icon-only" src="/icons/icon-map.svg" />
                </IonButton>
                {/*
              <IonButton onClick={() => setShowActionSheet(true)} className="visit-more-button" color="medium" fill="clear">
                <IonIcon slot="icon-only" name="more" mode="md" />
              </IonButton> */}
              </IonButtons>
            </ion-col>
          </ion-row>
        </ion-grid>
        {/* TODO: Will do favorites in a phase 2
      <IonActionSheet
        isOpen={state.showActionSheet}
        onDidDismiss={() => setShowActionSheet(false)}
        buttons={[{
          text: 'Archive',
          role: 'destructive',
          icon: 'archive',
          handler: () => {
            console.log('Delete clicked');
          }
        }, {
          text: 'Favorite',
          icon: 'star',
          handler: () => {
            console.log('Share clicked');
          }
        }, {
          text: 'Location',
          icon: 'map',
          handler: () => {
            console.log('Play clicked');
          }
        }, {
          text: 'Cancel',
          icon: 'close',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }]}
      >
      </IonActionSheet>
      */}
      </div>
    </>
  );
};

export default connect(
  null,
  { getLocation }
)(VisitHeader);
