import { api } from '../../services/api.js';
import { remoteAction } from '../../services/actionService.js';
import { selectVisit } from '../visits/selectors.js';

export const getLocation = () => (dispatch, getStore) => {
  const visit = selectVisit(getStore());

  const action = () => api.get(`/locations/${visit.locationId}`).then(({data}) => data);

  return dispatch(remoteAction({
    type: 'LOCATION_GET',
    action,
    metadata: {visitId: visit.id}
  }));
};
