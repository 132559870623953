import { connect } from 'react-redux';
import { pipe, split, map, pathOr } from 'ramda';
import DOMPurify from 'dompurify';
import React, { useEffect } from 'react';
import { format } from 'date-fns';
import {
  IonHeader,
  IonButtons,
  IonContent,
  IonItem,
  IonLabel,
  IonAvatar,
  IonButton,
  IonIcon,
  IonProgressBar,
  IonRefresher,
  IonRefresherContent,
  IonSlide,
  IonPage
} from '@ionic/react';
import { chevronBack, chevronForward } from 'ionicons/icons';
import './AgendaDetail.css';

import SlidesController from '../components/SlidesController';
import SlidesContainer from '../components/SlidesContainer';

import { capitalize } from '../utils/utils';
import language from '../custom-scripts/language';

import Header from '../components/Header';

import { getAgendaSessions  } from '../features/agendasessions/actions';
import { selectAgendaPage } from '../features/agendasessions/selectors';

const formatDate = date => format(new Date(date), 'h:mm aaa');

const gotoPresenterDetail = (agendaId, presenterId, push) => {
  return push(`/agenda/item/${agendaId}/presenter?presenterId=${presenterId}`);
};

const gotoContentDetail = (agendaId, contentId, push) => {
  return push(`/agenda/item/${agendaId}/content?agendaContentId=${contentId}`);
};

const AgendaDetail = ({
  match,
  history,
  records = [],
  isLoading,
  doRefresh,
  gotoNext,
  gotoPrev,
  gotoIndex,
  onSlideChange,
  currentIndex,
  defaultIndex,
}) => {

  useEffect(() => {
    if (currentIndex !== defaultIndex) {
      gotoIndex(defaultIndex);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [records.length]);

  return (
    <IonPage>
      <IonHeader>
        <Header match={match} visitBack={false} />
      </IonHeader>

      <IonContent color="light agenda-session">
        { isLoading && <IonProgressBar type="indeterminate"></IonProgressBar> }
        <IonRefresher slot="fixed" onIonRefresh={doRefresh} color="light">
          <IonRefresherContent color="light"></IonRefresherContent>
        </IonRefresher>

        { records.length > 0 &&
            <SlidesContainer pager={false} id="agenda-session-slides" onSlideChange={onSlideChange} index={currentIndex}>
              { records.map( agenda => (
                <IonSlide key={agenda.id} style={{width: '100vw', display: 'block', textAlign: 'left', color: 'black'}}>
                  <div className="subheader-padding">
                    <h2>{agenda.agendaTitle}</h2>
                    <ion-grid>
                      <ion-row justify-content-between>
                        <ion-col align-self-center size="8">
                          <p className="dates">
                            {formatDate(agenda.startTimeLocationFormula)} - {formatDate(agenda.endTimeLocationFormula)}
                          </p>
                          { /* TODO: MOve this to a selector */ pipe(
                            pathOr('', ['roomsBooked']),
                            split(','),
                            map( room => (
                              <p key={room} className="location">{room}</p>
                            )))(agenda)
                          }
                        </ion-col>
                        <ion-col align-self-center size="4">
                          <IonButtons>
                            <IonButton color="primary" fill="clear" onClick={gotoPrev} disabled={currentIndex <= 0}>
                              <IonIcon slot="icon-only" icon={chevronBack} />
                            </IonButton>
                            <IonButton color="primary" fill="clear" onClick={gotoNext} disabled={currentIndex >= records.length - 1}>
                              <IonIcon slot="icon-only" icon={chevronForward} />
                            </IonButton>
                          </IonButtons>
                        </ion-col>
                      </ion-row>
                    </ion-grid>
                  </div>
                  { (agenda.agendaSubtopics || agenda.presenters.length > 0) && (
                    <div className="list top-round agenda-list">
                      <div className="agenda-item-content">
                        { agenda.agendaSubtopics && (
                          <>
                            <h3>Session Overview</h3>
                            <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(agenda.agendaSubtopics)}} ></p>
                          </>
                        )}
                        { agenda.presenters.length > 0 && (<h3>{capitalize(language.speakerPlural)}</h3>) }
                      </div>
                      <div>
                        { agenda.presenters.map( presenter => (
                          <IonItem key={presenter.id} lines="full" onClick={() => gotoPresenterDetail(agenda.id, presenter.id, history.push)} button>
                            <div className="small-image">
                              <IonAvatar slot="start">
                                <img src={presenter.image || '/user.png'} alt={presenter.name}/>
                              </IonAvatar>
                            </div>
                            <IonLabel className="presenter-label">
                              <h3>{presenter.name}</h3>
                              <p className="title">{presenter.title}</p>
                              <p> {presenter.company} </p>
                            </IonLabel>
                          </IonItem>
                        ))}
                      </div>
                      <div className="agenda-item-content">
                        { agenda.visit_contents.length > 0 && (<h3>Content</h3>) }
                      </div>
                      <div className="content-list">
                        { agenda.visit_contents.map(content => (
                          <IonItem key={content.id} lines="full" onClick={() => gotoContentDetail(agenda.id, content.id, history.push)} button>
                            <IonIcon style={{marginLeft: '8px'}} slot="start" src={`/icons/doctype/${content.iconId}.svg`} />
                            <IonLabel>
                              <h3>{content.contentName}</h3>
                            </IonLabel>
                          </IonItem>
                        ))}
                      </div>
                    </div>
                  )}
                </IonSlide>
              ))}
            </SlidesContainer> }
      </IonContent>
    </IonPage>
  );
};

const mapDispatch = dispatch => {
  const doRefresh = (event) => {
    dispatch(getAgendaSessions({disableCache: true})).then(() => {
      event.detail.complete();
    }).catch(() => {
      event.detail.complete();
    });
  };
  return {
    doRefresh
  };
};

const onSlideChangeWithProps = (index, props) => {
  props.history.replace(`/agenda/item?sessionId=${props.records[index].id}`);
};

export default pipe(
  SlidesController(onSlideChangeWithProps),
  connect(selectAgendaPage, mapDispatch)
)(AgendaDetail);
