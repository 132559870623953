import { path } from 'ramda';
import { api } from '../../services/api.js';
import { remoteAction } from '../../services/actionService.js';

export const getAttendees = ({disableCache = false} = {}) => (dispatch, getStore) => {
  const visitId = getStore().global.visitId;
  const cachedAt = path(['attendees', visitId, 'cachedAt'], getStore());
  if(!disableCache && cachedAt){
    return;
  }
  const action = () => api.get(`/visits/${visitId}/attendees`).then(({data}) => data);
  return dispatch(remoteAction({
    type: 'ATTENDEES_GET',
    action,
    metadata: { visitId }
  }));
};
