import React, { Component } from 'react';
import { IonSlides } from '@ionic/react';

class SlidesContainer extends Component {

  constructor(props) {
    super(props);
    this.slidesRef = React.createRef();
  }

  componentDidMount(){
    const slides = this.slidesRef.current;
    this.slides = slides;
    slides.options = {
      initialSlide: this.props.index,
      speed: 400,
      autoplay: false
    };

    this.onSlideChange = () => {
      slides.getActiveIndex().then(this.props.onSlideChange);
    };

    slides.addEventListener('ionSlideDidChange', this.onSlideChange);
  }

  componentWillUnmount(){
    this.slides.removeEventListener('removeEventListener', this.onSlideChange);
  }

  componentDidUpdate(prevProps){
    if(prevProps.index !== this.props.index){
      const currentIndexProp = this.props.index;
      this.slides.getActiveIndex().then( activeIndex => {
        if(currentIndexProp !== activeIndex){
          this.slides.slideTo(currentIndexProp, this.slides.options.speed);
        }
      });
    }
  }

  render() {
    return (
      <IonSlides pager={this.props.pager} ref={this.slidesRef}>
        {this.props.children}
      </IonSlides>
    );
  }
}

export default SlidesContainer;
