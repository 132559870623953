import { format } from 'date-fns';
import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  IonHeader,
  IonContent,
  IonLabel,
  IonItem,
  IonNote,
  IonListHeader,
  IonProgressBar,
  IonSegment,
  IonSegmentButton,
  IonPage,
  IonSpinner,
} from '@ionic/react';
import './Visits.css';

import Header from '../components/Header';
import InfiniteScroll from '../components/InfiniteScroll';
import NotFoundList from '../components/NotFoundList';

import { selectVisitsPage } from '../features/visits/selectors';
import { gotoVisit, setVisitsFilter, getVisits } from '../features/visits/actions.js';


const VisitCard = ({
  id = '',
  visitName = '',
  arrivalDateTimeLocationFormula = '',
  departureDateTimeLocationFormula = '',
  locationNameFormula = '',
  gotoVisit,
}) => (
  <IonItem lines="full" id={`visit-item-${id}`} className="visit-item" button   onClick={() => gotoVisit({visitId: id})} >
    <IonNote className="visit-day" slot="start" color="primary">{format(new Date(arrivalDateTimeLocationFormula), 'iii d' )}</IonNote>
    <IonLabel>
      <h2>{visitName}</h2>
      <p>
        {arrivalDateTimeLocationFormula} - {departureDateTimeLocationFormula}
      </p>
      <p>
        {locationNameFormula}
      </p>
    </IonLabel>
  </IonItem>
);

const VisitGroup = ({visits, groupName, gotoVisit}) => (
  <>
    <IonListHeader>
      <IonLabel color="primary">{groupName}</IonLabel>
    </IonListHeader>
    <div className="list">
      { visits.map( visit => (
        <VisitCard key={visit.id} {...visit} gotoVisit={gotoVisit} />
      )) }
    </div>
  </>
);

const Visits = ({
  visitsContainer: {loading, errorMessage, filter},
  gotoVisit,
  groupedVisits,
  setVisitsFilter,
  loadVisits,
  totalCount,
  fetchedCount,
  loadPage,
}) => {
  const [ scrollContainer, setScrollContainer ] = useState(null);
  const ionContent = useRef(null);
  useEffect(() => {
    if(ionContent.current && !scrollContainer) {
      ionContent.current.getScrollElement().then( elm => {
        setScrollContainer(elm);
      });
    }
  });
  return (
    <IonPage>
      <IonHeader>
        <Header showBack={false} />
      </IonHeader>

      <IonContent color="light" ref={ionContent}>
        { loading && <IonProgressBar type="indeterminate"></IonProgressBar> }
        <div className="subheader-padding">
          <h2 className="primary screen-title" >My Visits</h2>
          <IonSegment onIonChange={e => setVisitsFilter(e.detail.value)} value={filter}>
            <IonSegmentButton class="upcoming-segment" value='upcoming'>
              <IonLabel>Upcoming</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton class="past-segment" value='past'>
              <IonLabel>Past Visits</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </div>
        <div>
          { (!loading && !errorMessage && groupedVisits.length === 0 && filter === 'upcoming') &&
              <NotFoundList iconSrc="/icons/icon-calendar-check.svg" title="Your schedule is free" description="When you are invited, your new visits will appear on this page."/>
          }
          { (!loading && !errorMessage && groupedVisits.length === 0 && filter === 'past') &&
              <NotFoundList iconSrc="/icons/icon-calendar-check.svg" title="No past visits" description="After visits are done, they will appear here."/>
          }
          { (!loading && errorMessage) &&
              <NotFoundList iconSrc="/icons/icon-error.svg" title="Error loading visits" description={errorMessage} />
          }
          <InfiniteScroll
            initialLoad={fetchedCount === 0}
            threshold={10}
            loadMore={() => loadVisits(loadPage, filter)}
            hasMore={totalCount === null || fetchedCount < totalCount}
            isLoading={loading}
            loader={<div className="loader" key={0}><IonSpinner /></div>}
            useWindow={false}
            getScrollParent={() => scrollContainer}
          >
            { groupedVisits.map(([groupName, items]) => (
              <VisitGroup key={groupName} groupName={groupName} visits={items} gotoVisit={gotoVisit} />
            ))}
          </InfiniteScroll>
        </div>
      </IonContent>
    </IonPage>
  );
};

const mapDispatchToProps = (dispatch, props) => ({
  gotoVisit: ({visitId}) => dispatch(gotoVisit({visitId, push: props.history.push})),
  setVisitsFilter: args => dispatch(setVisitsFilter(args)),
  loadVisits: (page, filter) => dispatch(getVisits(25, page * 25, filter)),
});

export default connect(
  selectVisitsPage,
  mapDispatchToProps
)(Visits);

