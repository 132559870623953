import { connect } from 'react-redux';
import React from 'react';
import {
  IonHeader,
  IonButton,
  IonContent,
  IonSlides,
  IonSlide,
  IonModal,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonPage,
} from '@ionic/react';
import language from '../custom-scripts/language.js';
import {
  login,
  register,
  submitConfirmationCode,
  resendConfirmationCode,
  setModalState,
  openModal,
  closeModal,
  sendVerificationCode,
  submitNewPassword
} from '../features/auth/actions.js';
import { loginPageSelector } from '../features/auth/selectors.js';

import {
  MODAL_CONFIRMATION_CODE_REQUIRED,
  MODAL_FORGOT_PASSWORD,
  MODAL_SUBMIT_NEW_PASSWORD,
  MODAL_LOGIN,
  MODAL_REGISTER
} from '../features/auth/modalConsts';
import { merge } from 'ramda';
import './Login.css';
import {
  ConfirmationCodeForm,
  ForgotPasswordForm,
  NewPasswordForm,
  LoginForm,
  RegistrationForm,
  ModalBack
} from '../components/LoginComponents';

const slideOpts = {
  initialSlide: 0,
  speed: 1e3
};

const Login = ({
  login,
  register,
  submitConfirmationCode,
  resendConfirmationCode,
  sendVerificationCode,
  submitNewPassword,
  setModalState,
  openModal,
  closeModal,
  errorMessage,
  registerEmail,
  authLoading,
  authModal,
  modalState,
}) => {
  return (
    <IonPage>
      <IonContent>
        <div className="login-container">
          <div className="info-container">
            <IonSlides pager={true} options={slideOpts}>
              <IonSlide>
                <div className="img-fluid p-3">
                  <img alt={language.logoAlt} src={`/custom-assets/${language.logo}`}/>
                </div>
              </IonSlide>
              <IonSlide>
                <p> Run and measure your entire visit or briefing program with one app.  </p>
              </IonSlide>
              <IonSlide>
                <p> VisitOps lets you create visits, build agendas, invite presenters, send surveys, and run reports. All on the Salesforce platform.  </p>
              </IonSlide>
            </IonSlides>
          </div>

          <div className="login-button-container">
            <div>
              <IonButton class="login-modal-button" color="primary" onClick={() => openModal(MODAL_LOGIN)} >
                Login
              </IonButton>
            </div>
            <div>
              <IonButton class="register-modal-button" color="primary" fill="clear" onClick={() => openModal(MODAL_REGISTER)} >
                Register
              </IonButton>
            </div>
          </div>
        </div>
        <IonModal isOpen={authModal}>
          <IonHeader>
            <IonToolbar color="light">
              <ModalBack modalState={modalState} setModalState={setModalState} />
              <IonTitle>{modalState === MODAL_REGISTER ? 'Register' : 'Login' }</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={closeModal}>Close</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent color="light">
            { modalState === MODAL_CONFIRMATION_CODE_REQUIRED &&
                <ConfirmationCodeForm
                  closeModal={closeModal}
                  submitConfirmationCode={submitConfirmationCode}
                  resendConfirmationCode={resendConfirmationCode}
                  errorMessage={errorMessage}
                  loading={authLoading} />
            }
            {
              modalState === MODAL_FORGOT_PASSWORD &&
              <ForgotPasswordForm
                sendVerificationCode={sendVerificationCode}
                errorMessage={errorMessage}
                loading={authLoading}/>
            }
            {
              modalState === MODAL_SUBMIT_NEW_PASSWORD &&
              <NewPasswordForm
                closeModal={closeModal}
                submitNewPassword={submitNewPassword}
                errorMessage={errorMessage}
                loading={authLoading} />
            }
            { modalState === MODAL_LOGIN &&
              <LoginForm
                closeModal={closeModal}
                login={login}
                errorMessage={errorMessage}
                loading={authLoading}
                setModalState={setModalState}/>
            }
            { modalState === MODAL_REGISTER  &&
              <RegistrationForm
                register={register}
                registerEmail={registerEmail}
                errorMessage={errorMessage}
                loading={authLoading}/>
            }
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default connect(
  loginPageSelector,
  (dispatch, {history}) => ({
    login: ({email, password}) => dispatch(login({email, password, push: history.push})),
    register: (data) => dispatch(register(history.push, data)),
    submitConfirmationCode: formData => dispatch(submitConfirmationCode(merge(formData, { push: history.push}))),
    resendConfirmationCode: () => dispatch(resendConfirmationCode()),
    sendVerificationCode: email => dispatch(sendVerificationCode(email)),
    submitNewPassword: formData => dispatch(submitNewPassword(merge(formData, { push: history.push }))),
    setModalState: state => dispatch(setModalState(state)),
    openModal: state => dispatch(openModal(state)),
    closeModal: () => dispatch(closeModal())
  })
)(Login);
