import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router-dom';
import { IonSplitPane, IonPage, IonContent } from '@ionic/react';

import { AUTH_NO, AUTH_YES } from '../features/auth/consts';
import VisitsPage from '../pages/Visits';
import AppStack from '../pages/AppStack';
import Menu from './Menu';
import NotFoundList from './NotFoundList';

const ConnectToAuthStatus = connect(
  state => ({
    authState: state.auth.status
  })
);

const Loading = () => (
  <IonPage>
    <IonContent className="details-content" color="light">
      <NotFoundList title="Loading" description="Loading the app..."/>
    </IonContent>
  </IonPage>
);

const Unauthorized = () => (
  <IonPage>
    <IonContent className="details-content" color="light">
      <NotFoundList title="Need to login" description="You need to login to view this page"/>
    </IonContent>
  </IonPage>
);

const MenuContainer = ConnectToAuthStatus(({history, authState}) => {
  if(authState === AUTH_NO) {
    return <Unauthorized />;
  }
  if(authState === AUTH_YES) {
    return (
      <IonSplitPane contentId="main">
        <Menu history={history} />
        <IonPage id="main">
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/visits" />} />
            <Route path="/visits" component={VisitsPage} />
            <Route path="/" component={AppStack} />
          </Switch>
        </IonPage>
      </IonSplitPane>
    );
  }
  return <Loading />;
});

export default MenuContainer;
