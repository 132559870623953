import React, { useContext } from 'react';
import { format } from 'date-fns';
import {
  IonList,
  IonItem,
  IonItemGroup,
  IonItemDivider,
  IonLabel,
  IonNote,
  IonButton,
  IonButtons,
  IonIcon,
  IonSlide
} from '@ionic/react';
import { chevronBack, chevronForward } from 'ionicons/icons';
import PushContext from './PushContext';

import SlidesController from '../components/SlidesController';
import SlidesContainer from '../components/SlidesContainer';

const gotoAgendaDetail = (id, push) => {
  return push(`/agenda/item?sessionId=${id}`);
};


const AgendaItem = ({id, name, start, presenters}) => {
  const push = useContext(PushContext);
  return (
    <IonItem className="agenda-item" lines="full" onClick={() => gotoAgendaDetail(id, push)} button>
      <IonNote slot="start" color="primary">{format(new Date(start), 'h:mm aaa' )}</IonNote>
      <IonLabel>
        <h3>{name}</h3>
        <p> {presenters.map(({name}) => name).join(', ')} </p>
      </IonLabel>
    </IonItem>
  );
};

const DayList = ({
  group: [day] = [],
  currentDay = 0,
  gotoNextDay,
  gotoPrevDay,
  daysCount,
  groupedItems = [],
  onSlideChange
}) => (
  <React.Fragment key="items">
    <IonItem className="day-label" lines="none" style={{paddingLeft: '8px'}}>
      <IonLabel>
        <h2>Day {currentDay + 1}</h2>
        <p>{day}</p>
      </IonLabel>

      { daysCount > 1 &&
          <IonButtons slot="end">
            <IonButton color="primary" fill="clear" onClick={gotoPrevDay} disabled={currentDay <= 0}>
              <IonIcon slot="icon-only" icon={chevronBack} />
            </IonButton>
            <IonButton color="primary" fill="clear" onClick={gotoNextDay} disabled={currentDay >= daysCount - 1}>
              <IonIcon slot="icon-only" icon={chevronForward} />
            </IonButton>
          </IonButtons> }
    </IonItem>

    <SlidesContainer id="agenda-slides" index={currentDay} onSlideChange={onSlideChange} pager={false}>
      { groupedItems.map( ([day, sessions]) => (
        <IonSlide key={day}>
          <IonList style={{width: '100vw'}}>
            { sessions.map((session) => (
              <AgendaItem
                key={session.id}
                day={day}
                id={session.id}
                presenters={session.presenters}
                start={session.startTimeLocationFormula}
                end={session.endTimeLocationFormula}
                name={session.agendaTitle}
              />))
            }
          </IonList>
        </IonSlide>))
      }
    </SlidesContainer>

  </React.Fragment>
);


const AgendaList = ({groupedItems = [], gotoNext, gotoPrev, currentIndex, onSlideChange}) => (
  <IonList className="list top-round">
    <IonItemGroup>
      <IonItemDivider sticky>
        <IonLabel>
          Agenda
        </IonLabel>
      </IonItemDivider>
      { groupedItems.length > 0 &&
        <DayList
          group={groupedItems[currentIndex]}
          currentDay={currentIndex}
          gotoNextDay={gotoNext}
          gotoPrevDay={gotoPrev}
          daysCount={groupedItems.length}
          groupedItems={groupedItems}
          onSlideChange={onSlideChange}
        />
      }
    </IonItemGroup>
  </IonList>
);

export default SlidesController(null, 'groupedItems')(AgendaList);
