import { Plugins } from '@capacitor/core';
import { intersection, assoc } from 'ramda';
import language from '../custom-scripts/language';
const { Device } = Plugins;

const appStoreData = {
  iOS: {
    src: 'apple-store-badge.svg',
    alt: 'Apple Store app download',
  },
  Android: {
    src: 'google-play-badge.png',
    alt: 'Google Play Store app download',
  },
};

/**
 * Builds array of data for supported mobile platforms
 *
 * @return Array<{src: string, alt: string, url: string}>
 */
export const mobilePlatformData = () => {
  const platforms = intersection(Object.keys(language.platforms), Object.keys(appStoreData));
  return platforms.map(key => assoc('url', language.platforms[key].appUrl, appStoreData[key]));
};

export const isMobileApp = () => {
  return Device.getInfo().then(info => {
    return info && info.platform !== 'web';
  }).catch(() => {
    return false;
  });
};
