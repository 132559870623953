const language = {
  baseURL: 'https://visitops-demo.my.salesforce.com',
  logo: 'logo.png',
  logoAlt: 'City Software Group',
  speaker: 'speaker',
  speakerPlural: 'speakers',
  platforms: {
    web: {},
    iOS: { appUrl: '' },
    Android: { appUrl: 'https://play.google.com/store/apps/details?id=com.visitops.myvisitops' },
  },
  helpText: 'If you have any questions or comments please email <a href="mailto:help@visitops.com">help@visitops.com</a>',
};

export default language;
