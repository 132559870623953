import { connect } from 'react-redux';
import { pipe } from 'ramda';


import SlidesController from '../components/SlidesController';
import ContentItemDetail from '../components/ContentItemDetail';

import { getContent } from '../features/content/actions';
import { selectContentItemDetailPage } from '../features/content/selectors.js';

const mapStateToProps = (state) => {
  return selectContentItemDetailPage(state);
};

const mapDispatch = dispatch => {
  const doRefresh = (event) => {
    dispatch(getContent({disableCache: true})).then(() => {
      event.detail.complete();
    }).catch(() => {
      event.detail.complete();
    });
  };
  return {
    doRefresh
  };
};

const onSlideChange = (index, props) => {
  props.history.replace(`/content/content?contentId=${props.records[index].id}`);
};

export default pipe(
  SlidesController(onSlideChange),
  connect(mapStateToProps, mapDispatch)
)(ContentItemDetail);
