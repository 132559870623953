import { connect } from 'react-redux';
import { pipe } from 'ramda';
import DOMPurify from 'dompurify';
import React from 'react';
import {
  IonHeader,
  IonButtons,
  IonContent,
  IonAvatar,
  IonButton,
  IonIcon,
  IonProgressBar,
  IonRow,
  IonCol,
  IonRefresher,
  IonRefresherContent,
  IonSlide,
  IonPage
} from '@ionic/react';
import { chevronBack, chevronForward } from 'ionicons/icons';
import './Presenter.css';

import SlidesController from '../components/SlidesController';
import SlidesContainer from '../components/SlidesContainer';
import Header from '../components/Header';

import { capitalize } from '../utils/utils';
import language from '../custom-scripts/language';

import { getAgendaSessions } from '../features/agendasessions/actions';
import { selectPresenterPage } from '../features/agendasessions/selectors';

const PresenterDetail = ({
  gotoNext,
  gotoPrev,
  onSlideChange,
  currentIndex,
  match,
  isLoading,
  doRefresh,
  records
}) => (
  <IonPage>
    <IonHeader>
      <Header match={match} visitBack={false} />
    </IonHeader>

    <IonContent color="light agenda-session">
      { isLoading && <IonProgressBar type="indeterminate"></IonProgressBar> }
      <IonRefresher slot="fixed" onIonRefresh={doRefresh} color="light">
        <IonRefresherContent color="light"></IonRefresherContent>
      </IonRefresher>
      { records.length > 0 &&
          <SlidesContainer pager={false} id="agenda-presenter-slides" onSlideChange={onSlideChange} index={currentIndex}>
            { records.map( presenter => (
              <IonSlide key={presenter.id} style={{width: '100vw', display: 'block', textAlign: 'left', color: 'black'}}>
                <div className="subheader-padding">
                  <ion-grid>
                    <IonRow className="presenter-header">
                      <IonCol style={{maxWidth: '100px'}} align-self-start>
                        <div className="page-image">
                          <IonAvatar>
                            <img src={presenter.image || '/user.png'} alt="presenter" />
                          </IonAvatar>
                        </div>
                      </IonCol>
                      <IonCol className="presenter-right" size="8" align-self-start>
                        <h2>{presenter.name}</h2>
                        <p className="speaker-chip-container"><span className="speaker-chip">{capitalize(language.speaker)}</span></p>
                        <p className="presenter-title">{presenter.title}</p>
                        <p>{presenter.company}</p>
                      </IonCol>
                    </IonRow>
                    <IonRow justify-content-between>
                      <ion-col align-self-center>
                        <p className="location"><a href={`mailto:${presenter.email}`} target="_blank" rel="noopener noreferrer">{presenter.email}</a></p>
                        <p className="location"><a href={`tel:${presenter.phone}`}>{presenter.phone}</a></p>
                      </ion-col>
                      <ion-col align-self-center>
                        <IonButtons>
                          <IonButton color="primary" fill="clear" onClick={gotoPrev} disabled={currentIndex <= 0}>
                            <IonIcon slot="icon-only" icon={chevronBack}/>
                          </IonButton>
                          <IonButton color="primary" fill="clear" onClick={gotoNext} disabled={currentIndex >= records.length - 1}>
                            <IonIcon slot="icon-only" icon={chevronForward}/>
                          </IonButton>
                        </IonButtons>
                      </ion-col>
                    </IonRow>
                  </ion-grid>
                </div>
                <div className="list top-round agenda-list">
                  <div className="agenda-item-content">
                    <h3>Bio</h3>
                    <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(presenter.bio)}} ></p>
                  </div>
                </div>
              </IonSlide> ))
            }
          </SlidesContainer>
      }
    </IonContent>
  </IonPage>
);

const mapStateToProps = (state, props) => {
  const sessionId = props.match.params.id;
  return selectPresenterPage(sessionId, state);
};

const mapDispatch = dispatch => {
  const doRefresh = (event) => {
    dispatch(getAgendaSessions({disableCache: true})).then(() => {
      event.detail.complete();
    }).catch(() => {
      event.detail.complete();
    });
  };
  return {
    doRefresh
  };
};

const onSlideChange = (index, props) => {
  props.history.replace(`/agenda/item/${props.session.id}/presenter?presenterId=${props.records[index].id}`);
};

export default pipe(
  SlidesController(onSlideChange),
  connect(mapStateToProps, mapDispatch)
)(PresenterDetail);
