import { pathOr } from 'ramda';

export const remoteAction = ({type, action, metadata}) => dispatch => {
  dispatch({
    type,
    state: 'REQUEST',
    metadata
  });

  return action().then( data => {
    dispatch({
      type,
      data,
      state: 'RESPONSE',
      metadata,
      date: new Date()
    });
    return data;
  }).catch( e => {
    const message = pathOr(e.message, ['response', 'data', 'message'], e);
    dispatch({
      type,
      e,
      error: message,
      state: 'ERROR',
      metadata,
      date: new Date()
    });
    return Promise.reject(e);
  });
};

export const remoteActionSimpleReducer = (action, state) => {
  if(action.state === 'REQUEST'){
    return {
      ...state,
      loading: true,
      errorMessage: ''
    };
  } else if(action.state === 'RESPONSE') {
    return {
      ...state,
      loading: false,
    };
  }
  return {
    ...state,
    loading: false,
    errorMessage: action.error
  };
};
