import { getAgendaSessions } from '../features/agendasessions/actions.js';
import { getSurveys } from '../features/feedback/actions.js';
import { getAttendees } from '../features/attendees/actions.js';
import { getContent } from '../features/content/actions.js';
import { handleAuthCheck, logout } from '../features/auth/actions';
import { toPairs, pipe, map, tail, zip, fromPairs } from 'ramda';
import qs from 'qs';
import { pathToRegexp } from 'path-to-regexp';

const routeActions = {
  '/logout': logout,
  '/agenda': getAgendaSessions,
  '/attendees': getAttendees,
  '/agenda/item': getAgendaSessions,
  '/agenda/item/:agendaId': getAgendaSessions,
  '/agenda/item/:agendaId/presenter': getAgendaSessions,
  '/attendees/attendee': getAttendees,
  '/feedback': getSurveys,
  '/feedback/form': getSurveys,
  '/content': getContent,
  '/content/content': getContent,
};

const pairs = pipe(
  toPairs,
  map(([route, action]) => {
    const keys = [];
    const re = pathToRegexp(route, keys);
    const keynames = keys.map(key => key.name);
    return {
      route,
      keys,
      keynames,
      re,
      action
    };
  })
)(routeActions);

const matchRoute = ({pathname}) => {
  return pairs.reduce( (acc, route) => {
    if(!acc.route){
      const match = route.re.exec(pathname);
      if(match){
        const params = pipe(
          tail,
          zip(route.keynames),
          fromPairs
        )(match);
        return {
          route: route.route,
          apiAction: route.action,
          params
        };
      }
      return acc;
    }
    return acc;
  }, {});
};

export const routeApiMiddleware = store => next => action => {
  if(action.type === '@@router/LOCATION_CHANGE'){
    const { pathname, search, hash } = action.payload.location;
    const { apiAction, params } = matchRoute({pathname});
    const parsedSearch = qs.parse(search, { ignoreQueryPrefix: true });
    if (parsedSearch && parsedSearch.visitId) {
      localStorage.visitId = parsedSearch.visitId;
    }
    store.dispatch(handleAuthCheck(pathname, action.payload.push)).then(() => {
      if(apiAction) {
        store.dispatch(apiAction(params, parsedSearch, hash));
      }
    }).catch(e => {
      console.error(e);
    });
    return next(action);
  }
  return next(action);
};
