import { assoc } from 'ramda';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  IonButtons,
  IonMenuButton,
  IonToolbar,
  IonTitle,
  IonButton,
  IonIcon,
  IonBackButton
} from '@ionic/react';
import { chevronBack } from 'ionicons/icons';
import NotificationModal from '../modals/NotificationModal';
import { selectHasUnreadNotifications } from '../features/notifications/selectors';

const Header = ({ showBack = true, visitBack = true, match, hasUnreadNotifications }) => {
  const [ state, setState ] = useState({showModal: false, showActionSheet: false});
  const showModal = () => {
    setState(assoc('showModal', true));
  };
  const hideModal = () => {
    setState(assoc('showModal', false));
  };
  return (
    <>
      <NotificationModal hideModal={hideModal} isModalOpen={state.showModal} />
      <IonToolbar color="light">
        { showBack &&
          <IonButtons slot="start">
            { visitBack ?
              <IonButton color="medium"><Link to="/visits"><IonIcon size="large" icon={chevronBack}></IonIcon>My Visits</Link></IonButton> :
              <IonBackButton color="medium" defaultHref={`/${match.params.tab}`} /> }
          </IonButtons> }
        <IonTitle></IonTitle>
        <IonButtons slot="end">
          <IonButton color={hasUnreadNotifications ? 'primary' : 'medium'} onClick={showModal}>
            <IonIcon slot="icon-only" src="/icons/icon-bell.svg" />
          </IonButton>
          <IonMenuButton color="medium">
            <IonIcon src="/icons/icon-user.svg" size="large" />
          </IonMenuButton>
        </IonButtons>
      </IonToolbar>
    </>
  );
};

export default connect(selectHasUnreadNotifications)(Header);
