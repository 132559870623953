import qs from 'qs';
import { pathOr, propEq, prop, pipe } from 'ramda';
import { selectVisit, selectVisitLoading } from '../visits/selectors';

const defaultObject = {};

export const selectAttendeesPage = (state) => {
  const visit = selectVisit(state);
  const container = state.attendees[visit.id] || defaultObject;
  const records = container.records;

  return {
    attendees: container,
    visitLoading: selectVisitLoading(state),
    records,
    visit
  };
};

export const selectAttendeeDetailPage = (state) => {
  const visit = selectVisit(state);
  const container = state.attendees[visit.id] || defaultObject;
  const records = container.records || [];

  const defaultIndex = pipe(
    pathOr('', ['router', 'location', 'search']),
    s => qs.parse(s, {ignoreQueryPrefix: true}),
    prop('attendeeId'),
    id => records.findIndex(propEq('id', id)),
    id => id === -1 ? 0 : id
  )(state);

  return {
    isLoading: pathOr(false, ['attendees', visit.Id, 'loading'], state),
    records,
    defaultIndex,
    visit
  };
};
