import { connect } from 'react-redux';
import React from 'react';
import PushContext from './PushContext';

class RouterRedux extends React.Component {
  constructor(props) {
    super(props);
    this.historyPush = this.props.history.push;
    this.listenUnregisterCallback = this.props.history.listen(this.historyChange.bind(this));
  }

  historyChange(location, action) {
    this.props.locationChange({
      location,
      action,
      push: this.historyPush,
    });
  }

  componentDidMount() {
    this.props.locationChange({
      push: this.historyPush,
      location: this.props.history.location,
      action: this.props.history.action,
    });
  }

  componentWillUnmount() {
    this.listenUnregisterCallback && this.listenUnregisterCallback();
  }

  render () {
    return (
      <PushContext.Provider value={this.props.history.push}>
        {this.props.children}
      </PushContext.Provider>
    );
  }
}

export default connect(
  state => ({
    errorMessage: state.auth.errorMessage
  }),
  dispatch => ({
    locationChange: ({location, action, push}) => dispatch({
      type: '@@router/LOCATION_CHANGE',
      payload: {
        location,
        action,
        push
      }
    })
  })
)(RouterRedux);
