import { Auth, API } from 'aws-amplify';

const apiCall = async (type, url, params = {}) => {
  const session = await Auth.currentSession();
  const authToken = session.getIdToken().getJwtToken();
  const opts = {
    headers: { Authorization: authToken },
    response: true,
    ...params,
  };
  return API[type]('api', url, opts);
};

export const api = {
  post: (url, body) => apiCall('post', url, { body }),
  get: (url, queryStringParameters = {}) => apiCall('get', url, { queryStringParameters }),
  put: (url, body) => apiCall('put', url, { body }),
  patch: (url, body) => apiCall('patch', url, { body }),
  delete: (url) => apiCall('delete', url),
};
