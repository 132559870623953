import React from 'react';
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonContent,
  IonTitle,
  IonButton,
  IonModal
} from '@ionic/react';

const Privacy = ({isModalOpen, hideModal}) => (
  <IonModal isOpen={isModalOpen}>
    <IonHeader>
      <IonToolbar color="light">
        <IonTitle>Privacy Policy</IonTitle>
        <IonButtons slot="end">
          <IonButton onClick={hideModal}>Close</IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>

    <IonContent color="light">
      <p>Privacy policy will go here.</p>
    </IonContent>
  </IonModal>
);

export default Privacy;
