import React from 'react';
import { Link } from 'react-router-dom';
import {
  IonContent,
  IonPage,
} from '@ionic/react';
import { mobilePlatformData } from '../utils/mobile';

import './MobileAppLinks.css';

const platforms = mobilePlatformData();

const MobileAppLink = ({ data }) => (
  <a href={data.url}>
    <img alt={data.alt} src={`/${data.src}`} />
  </a>
);

const MobileAppLinks = () => {
  return (
    <IonPage>
      <IonContent color="light">
        <div className="mobile-app-download">
          <h2>
            {platforms.length === 0
              ? 'There are no mobile apps available'
              : 'Download the mobile app'
            }
          </h2>
          <div className="mobile-app-links">
            {platforms.map(platform => (
              <MobileAppLink data={platform} key={platform.src} />
            ))}
          </div>
          <p>
            <Link to="/visits">continue in browser...</Link>
          </p>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default MobileAppLinks;
