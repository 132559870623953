import qs from 'qs';
import { format } from 'date-fns';
import { pipe, pathOr, propOr, prop, map, merge, filter, groupBy, toPairs, reverse } from 'ramda';

export const filterItems = (timeFilter = 'upcoming') => (items = []) => {
  const now = (new Date()).getTime();
  return pipe(
    map(item => merge(item, {
      month: format(new Date(item.arrivalDateTimeLocationFormula), 'MMM yyyy'),
      endTime: new Date(item.departureDateTimeLocationFormula).getTime()
    })),
    filter(({endTime}) => timeFilter === 'upcoming' ? endTime > now : endTime <= now),
    items => (timeFilter === 'upcoming' ? reverse(items) : items)
  )(items);
};

const groupItems = (timeFilter = 'upcoming', groupItemsBy) => (items = []) => {
  const groupByProp = groupItemsBy === 'date' ? 'month' : 'locationNameFormula';
  return pipe(
    filterItems(timeFilter),
    groupBy(prop(groupByProp)),
    toPairs
  )(items);
};

export const selectVisitId = state => {
  return state.global.visitId;
};

export const selectVisit = state => {
  const visitId = selectVisitId(state);
  const visit = pathOr({id: visitId}, ['visitDetails', visitId, 'visit', 'data'], state);
  return visit;
};

export const selectVisitLoading = state => {
  const visitId = selectVisitId(state);
  return pathOr(false, ['visitDetails', visitId, 'visit', 'loading'], state);
};

export const selectDetailPage = state => ({
  user: state.global.user,
  visit: selectVisit(state),
  loading: selectVisitLoading(state)
});

export const selectVisitsPage = state => {
  const groupBy = pipe(
    pathOr('', ['router', 'location', 'search']),
    s => qs.parse(s, {ignoreQueryPrefix: true}),
    propOr('date', 'groupBy'),
  )(state);

  const groupedVisits = pipe(
    propOr([], 'records'),
    groupItems(state.visits.filter, groupBy)
  )(state.visits);

  const totalCount = state.visits[`${state.visits.filter}TotalCount`];
  const fetchedCount = groupedVisits.reduce((count, group) => {
    const [, visits] = group;
    return (count + visits.length);
  }, 0);
  const loadPage = Math.ceil(groupedVisits.length / 25);

  return {
    visitsContainer: state.visits,
    groupBy,
    groupedVisits,
    totalCount,
    loadPage,
    fetchedCount,
  };
};
