import { Auth, Storage } from 'aws-amplify';
import { api } from '../../services/api.js';
import { remoteAction } from '../../services/actionService.js';
import { getVisit } from '../visits/actions';
import { path, merge, pipe, map, fromPairs } from 'ramda';
import { registerPushToken, getNotifications }  from '../notifications/actions';
import { mobilePlatformData, isMobileApp } from '../../utils/mobile';

import { AUTH_YES, AUTH_NO } from './consts';
import { profilePictureName } from '../../consts';


const getUserAttributes = () => Auth.currentAuthenticatedUser()
  .then(user => Auth.userAttributes(user))
  .then(pipe(
    map(({Name, Value}) => [Name, Value]),
    fromPairs
  ));

export const updateUser = ({profilePictureName}) => remoteAction({
  type: 'USER_UPDATE',
  action: () => Auth.currentUserInfo().then(info => {
    const key = `${info.id}/${profilePictureName}`;
    return api.patch('/users', {
      profilePictureName: key
    }).then(() => {
      return Auth.currentAuthenticatedUser().then( user => {
        return Auth.updateUserAttributes(user, { picture: key });
      });
    });
  }),
  metadata: { profilePictureName }
});

export const getCurrentUser = remoteAction({
  type: 'USER_ME_GET',
  action: () => getUserAttributes()
    .then(attributes => {
      console.log('user attr', attributes);
      if(attributes.picture) {
        return Storage.get(profilePictureName, {
          level: 'protected'
        }).then(picture => {
          return merge(attributes, picture ? { picture: picture + '#' + new Date().getTime() } : {});
        });
      }
      return attributes;
    })
});

export const register = (push, {email, password, givenName, familyName, confirmPassword}) => {
  const action = () => Promise.resolve().then(() => {
    if (password !== confirmPassword ) {
      return Promise.reject(new Error('Passwords do not match'));
    }
    return Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
        given_name: givenName,
        family_name: familyName,
        nickname: givenName,
        profile: '',
        picture: '',
        updated_at: String(Math.floor((new Date()).getTime() / 1000))
      }});
  });

  return remoteAction({
    type: 'AUTH_REGISTER',
    action,
    metadata: {
      username: email,
      password
    }
  });
};

const syncVisitCognitoUsers = () => remoteAction({
  type: 'SYNC_COGNITO',
  action: () => api.post('/users/sync_cognito')
});

export const login = ({email, password, push, postLoginPath = '/visits'}) => dispatch =>  {
  const action = () => Auth.signIn(email, password).then(cognitoUser => cognitoUser.signInUserSession);

  return dispatch(remoteAction({
    type: 'AUTH_LOGIN',
    action,
    metadata: {
      username: email,
      password
    }
  })).then(() => {
    return dispatch(getCurrentUser);
  }).then(() => {
    return dispatch(syncVisitCognitoUsers());
  }).then(() => {
    dispatch(changeAuthStatus(AUTH_YES));
    setTimeout(() => push(postLoginPath));
  }).then(() => {
    return dispatch(registerPushToken());
  }).then(() => {
    return dispatch(getNotifications(25));
  });
};

export const submitConfirmationCode = ({push, confirmationCode}) => (dispatch, getStore) => {
  console.log({confirmationCode});
  const username = path(['auth', 'username'], getStore());
  const password = path(['auth', 'password'], getStore());

  const action = () => Auth.confirmSignUp(username, confirmationCode);

  return dispatch(remoteAction({
    type: 'AUTH_SUBMIT_CONFIRMATION_CODE',
    action
  })).then(async () => {
    const isMobile = await isMobileApp();
    const mobilePlatformCount = mobilePlatformData().length;
    dispatch(login({
      email: username,
      password,
      push,
      postLoginPath: !isMobile && mobilePlatformCount > 0 ? '/mobile-app-links' : '/visits',
    }));
  });
};

export const resendConfirmationCode = () => (dispatch, getStore) => {
  const username = path(['auth', 'username'], getStore());

  const action = () => Auth.resendSignUp(username);

  return dispatch(remoteAction({
    type: 'AUTH_RESEND_CONFIRMATION_CODE',
    action
  }));
};

const changePassword = ({ oldPassword, newPassword }) => Auth.currentAuthenticatedUser().then(user => Auth.changePassword(user, oldPassword, newPassword));

export const submitChangePassword = ({oldPassword, newPassword, newPasswordConfirmed }) => dispatch => {
  const action = () => Promise.resolve().then(() => {
    if (newPassword !== newPasswordConfirmed) {
      return Promise.reject(new Error('Passwords do not match'));
    }
    return changePassword({ oldPassword, newPassword });
  });

  return dispatch(remoteAction({
    type: 'AUTH_CHANGE_PASSWORD',
    action
  }));
};

export const submitNewPassword = ({verificationCode, newPassword, newPasswordConfirmed, push}) => (dispatch, getStore) => {
  const email = getStore().auth.requiredPasswordResetData.email;
  const action = () => Promise.resolve().then(() => {
    if (newPassword !== newPasswordConfirmed) {
      return Promise.reject(new Error('Passwords do not match'));
    }
    return Auth.forgotPasswordSubmit(email, verificationCode, newPassword);
  });
  return dispatch(remoteAction({
    type: 'AUTH_SUBMIT_NEW_PASSWORD',
    action
  })).then(() => {
    return dispatch(login({
      email,
      password: newPassword,
      push
    }));
  });
};

export const sendVerificationCode = ({email}) => dispatch => {
  const action = () => Auth.forgotPassword(email).then(data => {
    const error = new Error('New password required.');
    error.code = 'NEW_PASSWORD_REQUIRED';
    error.metadata = {
      data,
      email
    };
    return Promise.reject(error);
  });

  return dispatch(remoteAction({
    type: 'AUTH_SEND_VERIFICATION',
    action
  }));
};

export const changeAuthStatus = status => ({
  type: 'AUTH_STATUS_CHANGE',
  payload: status
});

export const setModalState = state => ({
  type: 'AUTH_SET_MODAL_STATE',
  payload: state
});

const setModal = state => ({
  type: 'AUTH_SET_MODAL',
  payload: state
});

const clearError = ({
  type: 'AUTH_CLEAR_ERROR'
});

export const openModal = state => dispatch => {
  dispatch(clearError);
  dispatch(setModalState(state));
  dispatch(setModal(false));
  setTimeout(() => dispatch(setModal(true)), 10);
};

export const closeModal = () => setModal(false);

const checkCognitoAuth = () => dispatch => {
  return Auth.currentAuthenticatedUser().then(cognitoUser => {
    if (!cognitoUser) {
      return Promise.reject('No current user');
    }
    const getUserSession = () => Auth.currentSession();

    return dispatch(remoteAction({
      type: 'AUTH_CHECK',
      action: getUserSession,
      metadata: { cognitoUser }
    })).then(() => {
      return dispatch(getCurrentUser);
    }).then(() => {
      dispatch(changeAuthStatus(AUTH_YES));
    });
  });
};


export const handleAuthCheck = (pathname, push) => (dispatch, getStore) => {
  const authStaus = getStore().auth.status;
  if(authStaus === AUTH_YES && pathname === '/login'){
    console.log('push visits');
    setTimeout(() => push('/visits'));
    return Promise.resolve();
  }
  if(authStaus === AUTH_YES || pathname === '/callback'){
    return Promise.resolve();
  }
  if(authStaus === AUTH_NO){
    if(pathname !== '/login'){
      setTimeout(() => push('/login'));
    }
    const error =  new Error('not authenticated');
    return Promise.reject(error);
  }
  return Promise.resolve().then(() => {
    return dispatch(checkCognitoAuth());
  }).then(() => {
    return dispatch(syncVisitCognitoUsers());
  }).then(() => {
    if (pathname === '/mobile-app-links') {
      return Promise.resolve();
    }
    const visitId = getStore().global.visitId;
    if(pathname === '/login' || !visitId){
      return push('/visits');
    }
    return dispatch(getVisit({visitId}));
  }).then(() => {
    return dispatch(registerPushToken());
  }).then(() => {
    return dispatch(getNotifications(25));
  }).catch(e => {
    console.log('Auth check error', JSON.stringify(e, null, 2));
    console.error(e);
    dispatch(changeAuthStatus(AUTH_NO));
    if(pathname !== '/login'){
      setTimeout(() => push('/login'));
    }
    throw e;
  });
};

export const logout = () => {
  Auth.signOut();
  window.location.reload();
};
