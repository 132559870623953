import { merge, path, } from 'ramda';
import qs from 'qs';

const initialState = {
  visitId: localStorage.visitId || '',
  agendaId: null,
  user: {}
};

const reducer = (state = initialState, action) => {
  if(action.type === 'VISIT_SELECT'){
    return merge(state, { visitId: action.payload.visitId });
  }
  if(action.type === 'USER_ME_GET'){
    if(action.state === 'REQUEST'){
      return merge(state, { loading: true });
    } else if(action.state === 'RESPONSE') {
      return merge(state, { loading: false, user: action.data});
    } else {
      return merge(state, { loading: false });
    }
  }
  const queryParams = path(['payload', 'location', 'search'], action);
  const pathname = path(['payload', 'location', 'pathname'], action);
  if(action.type === '@@router/LOCATION_CHANGE' &&  pathname === '/agenda/item' && queryParams){
    const search = qs.parse(queryParams, {ignoreQueryPrefix: true});
    return merge(state, { agendaId: search.id });
  }
  if(action.type === '@@router/LOCATION_CHANGE' && queryParams){
    const search = qs.parse(queryParams, {ignoreQueryPrefix: true});
    if (search.visitId) {
      return merge(state, { visitId: search.visitId });
    }
    return state;
  }
  return state;
};

export default reducer;


