import { connect } from 'react-redux';
import { pipe } from 'ramda';
import DOMPurify from 'dompurify';
import React from 'react';
import {
  IonHeader,
  IonButtons,
  IonContent,
  IonAvatar,
  IonButton,
  IonIcon,
  IonProgressBar,
  IonRow,
  IonCol,
  IonRefresher,
  IonRefresherContent,
  IonSlide,
  IonPage
} from '@ionic/react';
import { chevronBack, chevronForward } from 'ionicons/icons';

import './Presenter.css';

import SlidesContainer from '../components/SlidesContainer';
import SlidesController from '../components/SlidesController';
import Header from '../components/Header';

import { capitalize } from '../utils/utils';
import language from '../custom-scripts/language';

import { getAttendees } from '../features/attendees/actions';
import { selectAttendeeDetailPage } from '../features/attendees/selectors.js';

const AttendeeDetail = ({
  match,
  isLoading,
  records,
  doRefresh,
  gotoNext,
  gotoPrev,
  onSlideChange,
  currentIndex
}) => {
  return (
    <IonPage>
      <IonHeader>
        <Header match={match} visitBack={false} />
      </IonHeader>

      <IonContent color="light agenda-session">
        { isLoading && <IonProgressBar type="indeterminate"></IonProgressBar> }
        <IonRefresher slot="fixed" onIonRefresh={doRefresh} color="light">
          <IonRefresherContent color="light"></IonRefresherContent>
        </IonRefresher>

        { !isLoading && records.length > 0 &&
            <SlidesContainer pager={false} id="attendee-slides" onSlideChange={onSlideChange} index={currentIndex}>
              { records.map( attendee => (
                <IonSlide key={attendee.id} style={{width: '100vw', minHeight: 'calc(100vh - 125px)', display: 'block', textAlign: 'left', color: 'black'}}>
                  <div className="subheader-padding">
                    <ion-grid>
                      <IonRow className="presenter-header">
                        <IonCol align-self-start>
                          <div className="page-image">
                            <IonAvatar>
                              <img src={attendee.profileUrl || attendee.image || '/user.png'} alt="attendee" />
                            </IonAvatar>
                          </div>
                        </IonCol>
                        <IonCol className="presenter-right" size="8" align-self-start>
                          <h2>{attendee.name}</h2>
                          {attendee.isPresenter && <p className="speaker-chip-container"><span className="speaker-chip">{capitalize(language.speaker)}</span></p>}
                          <p className="attendee-title">{attendee.title}</p>
                          <p>{attendee.company}</p>
                        </IonCol>
                      </IonRow>
                      <IonRow justify-content-between>
                        <ion-col align-self-center>
                          <p className="location"><a href={`mailto:${attendee.email}`} target="_blank" rel="noopener noreferrer">{attendee.email}</a></p>
                          <p className="location"><a href={`tel:${attendee.phone}`}>{attendee.phone}</a></p>
                        </ion-col>
                        <ion-col align-self-center>
                          <IonButtons>
                            <IonButton color="primary" fill="clear" onClick={gotoPrev} disabled={currentIndex <= 0}>
                              <IonIcon slot="icon-only" icon={chevronBack} />
                            </IonButton>
                            <IonButton color="primary" fill="clear" onClick={gotoNext} disabled={currentIndex >= records.length - 1}>
                              <IonIcon slot="icon-only" icon={chevronForward} />
                            </IonButton>
                          </IonButtons>
                        </ion-col>
                      </IonRow>
                    </ion-grid>
                  </div>
                  { attendee.bio && <div className="list top-round agenda-list">
                    <div className="agenda-item-content">
                      <h3>Bio</h3>
                      <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(attendee.bio)}} ></p>
                    </div>
                  </div>
                  }
                </IonSlide> ))
              }
            </SlidesContainer>
        }
      </IonContent>
    </IonPage>
  );
};

const mapDispatch = dispatch => {
  const doRefresh = (event) => {
    dispatch(getAttendees({disableCache: true})).then(() => {
      event.detail.complete();
    }).catch(() => {
      event.detail.complete();
    });
  };
  return {
    doRefresh
  };
};

const onSlideChange = (index, props) => {
  props.history.replace(`/attendees/attendee?attendeeId=${props.records[index].id}`);
};

export default pipe(
  SlidesController(onSlideChange),
  connect(selectAttendeeDetailPage, mapDispatch)
)(AttendeeDetail);
