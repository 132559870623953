import React, { useState } from 'react';
import { connect } from 'react-redux';
import { find } from 'ramda';
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonContent,
  IonTitle,
  IonList,
  IonItem,
  IonLabel,
  IonButton,
  IonProgressBar,
  IonModal,
  IonRefresher,
  IonRefresherContent,
  IonIcon,
} from '@ionic/react';
import { chevronBack } from 'ionicons/icons';
import { selectNotificationModal } from '../features/notifications/selectors';
import { getNotifications, setNotificationsAsViewed } from '../features/notifications/actions';

import './Notifications.css';

const NotificationModal = ({
  isLoading,
  isModalOpen,
  hideModal,
  notifications,
  /* hasUnreadNotifications, - not used by this component */
  doRefresh,
  setAsViewed,
}) => {
  const [ state, setState ] = useState({ notification: null });
  const showNotification = (id) => {
    const notification = find(n => n.id === id)(notifications);
    if (!notification) {
      return;
    }
    setState({ notification });

    if (!notification.viewed) {
      setAsViewed([id]);
    }
  };
  const showAll = () => {
    setState({ notification: null });
  };
  const closeModal = () => {
    hideModal();
    showAll();
  };
  const { notification } = state;
  return (
    <IonModal isOpen={isModalOpen}>
      <IonHeader>
        <IonToolbar color="light">
          {notification && (
            <IonButtons slot="start">
              <IonButton color="medium" onClick={showAll}>
                <IonIcon size="large" icon={chevronBack}></IonIcon>Back
              </IonButton>
            </IonButtons>
          )}
          <IonTitle>Notifications</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={closeModal}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      {notification
        ?
        (<IonContent color="light">
          <div className="notification">
            <h2>{notification.notificationSubject}</h2>
            <p>{notification.notificationBody}</p>
          </div>
        </IonContent>)
        :
        (<IonContent color="light">
          { isLoading && <IonProgressBar type="indeterminate"></IonProgressBar> }

          <IonRefresher slot="fixed" onIonRefresh={doRefresh} color="light">
            <IonRefresherContent color="light"></IonRefresherContent>
          </IonRefresher>

          <IonList>
            { notifications.map( (n, i) => (
              <IonItem key={i} button lines="full" onClick={() => showNotification(n.id)}>
                <IonLabel style={{paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px'}}>
                  <h2> { !n.viewed && <span className="new-bullet">∙ </span>}{n.notificationSubject}</h2>
                  <p>{n.notificationBody}</p>
                </IonLabel>
              </IonItem>
            ))}
          </IonList>
        </IonContent>)
      }
    </IonModal>
  );
};

const mapDispatch = dispatch => {
  const doRefresh = (event) => {
    dispatch(getNotifications(25)).then(() => {
      event.detail.complete();
    });
  };
  const setAsViewed = ids => dispatch(setNotificationsAsViewed(ids));
  return {
    doRefresh,
    setAsViewed,
  };
};

export default connect(selectNotificationModal, mapDispatch)(NotificationModal);
