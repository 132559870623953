import React from 'react';
import { connect } from 'react-redux';
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonContent,
  IonTitle,
  IonButton,
  IonModal,
  IonAvatar,
  IonProgressBar,
  IonItem,
  IonList,
  IonInput,
  IonLabel,
  IonIcon,
} from '@ionic/react';

import ErrorMessage from '../components/ErrorMessage';
import { changeProfileImage } from '../features/profile/actions';
import './ProfileModal.css';

const ProfileModal = ({
  isLoading,
  isModalOpen,
  hideModal,
  user,
  changeProfileImage,
  errorMessage
}) => {
  return (
    <IonModal isOpen={isModalOpen}>
      <IonHeader>
        <IonToolbar color="light">
          <IonTitle>Attendee Profile</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={hideModal}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      { isLoading && <IonProgressBar type="indeterminate"></IonProgressBar> }
      <IonContent color="light">
        <div className="profile-image">
          <IonAvatar>
            <img src={user.picture || './user.png'} alt="profile"/>
            <div className="profile-image-input">
              <input className="hidden" type="file" title="Photo" accept=".jpg, .jpeg, .png" onChange={changeProfileImage}/>
              <div>
                <IonIcon slot="icon-only" src="../../icons/icon-edit.svg"/>
              </div>
            </div>
          </IonAvatar>
        </div>
        <IonList className="profile-info">
          <ErrorMessage errorMessage={errorMessage} />
          <IonItem lines="full">
            <IonLabel position="stacked">First Name</IonLabel>
            <IonInput disabled="true" value={user.given_name}/>
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="stacked">Last Name</IonLabel>
            <IonInput disabled="true" value={user.family_name}/>
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="stacked">Email</IonLabel>
            <IonInput disabled="true" value={user.email}/>
          </IonItem>
        </IonList>
      </IonContent>
    </IonModal>
  );
};

export default connect(
  state => ({
    user: state.global.user,
    isLoading: state.profile.isLoading,
    errorMessage: state.profile.errorMessage,
  }),
  dispatch => ({
    changeProfileImage: event => dispatch(changeProfileImage({event}))
  })
)(ProfileModal);
