import { any } from 'ramda';

export const selectHasUnreadNotifications = state => {
  const { records } = state.notifications;
  const hasUnreadNotifications = any(n => !n.viewed)(records);
  return { hasUnreadNotifications };
};

export const selectNotificationModal = state => {
  const { records, loading, errorMessage } = state.notifications;
  return {
    notifications: records,
    isLoading: loading,
    errorMessage,
  };
};
