import React from 'react';
import {
  IonItem,
  IonLabel,
  IonInput
} from '@ionic/react';

const Input = ({prop, state, onChange, title, type, required, disabled = false}) => (
  <IonItem lines="full">
    <IonLabel position="stacked">{title}</IonLabel>
    <IonInput disabled={disabled} name={prop} type={type} value={state[prop] || ''} onIonChange={onChange(prop)} required={required}></IonInput>
  </IonItem>
);

export default Input;