import React from 'react';

import { IonIcon } from '@ionic/react';

const NotFoundList = ({iconSrc, title, description}) => (
  <div className="not-found-list">
    {iconSrc && <IonIcon src={iconSrc} color="primary"/> }
    <h2>{title}</h2>
    <p>{description}</p>
  </div>
);

export default NotFoundList;
