import { connect } from 'react-redux';
import { pipe } from 'ramda';

import SlidesController from '../components/SlidesController';
import ContentItemDetail from '../components/ContentItemDetail';

import { getAgendaSessions } from '../features/agendasessions/actions';
import { selectAgendaContentPage } from '../features/agendasessions/selectors';

const mapStateToProps = (state, props) => {
  const sessionId = props.match.params.id;
  return selectAgendaContentPage(sessionId, state);
};

const mapDispatch = dispatch => {
  const doRefresh = (event) => {
    dispatch(getAgendaSessions({disableCache: true})).then(() => {
      event.detail.complete();
    }).catch(() => {
      event.detail.complete();
    });
  };
  return {
    doRefresh
  };
};

const onSlideChange = (index, props) => {
  props.history.replace(`/agenda/item/${props.session.id}/content?agendaContentId=${props.records[index].id}`);
};

export default pipe(
  SlidesController(onSlideChange),
  connect(mapStateToProps, mapDispatch)
)(ContentItemDetail);
