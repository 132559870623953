import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import visits from '../features/visits/reducer.js';
import visitDetails from '../features/agendasessions/reducer.js';
import toastContainer from '../features/toast/reducer.js';
import attendees from '../features/attendees/reducer.js';
import content from '../features/content/reducer.js';
import global from '../features/global/reducer.js';
import auth from '../features/auth/reducer.js';
import locations from '../features/locations/reducer';
import notifications from '../features/notifications/reducer';
import feedback from '../features/feedback/reducer';
import profile from '../features/profile/reducer';

const rootReducer = history => combineReducers({
  auth,
  global,
  visits,
  visitDetails,
  attendees,
  feedback,
  profile,
  content,
  locations,
  notifications,
  toastContainer,
  router: connectRouter(history)
});

export default rootReducer;
