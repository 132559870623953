import { path } from 'ramda';
import { api } from '../../services/api.js';
import { remoteAction } from '../../services/actionService.js';

export const getSurveys = ({disableCache = false} = {}) => (dispatch, getStore) => {
  const visitId = getStore().global.visitId;
  const cachedAt = path(['visitDetails', visitId, 'surveys', 'cachedAt'], getStore());
  const getSurveys = () => api.get(`/visits/${visitId}/surveys`).then(({data}) => data);
  if(!disableCache && cachedAt){
    return;
  }
  return dispatch(remoteAction({
    type: 'SURVEYS_GET',
    action: getSurveys,
    metadata: { visitId }
  }));
};
