import { IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';
import React from 'react';
import { Route } from 'react-router';
import SchedulePage from './Schedule';
import AgendaDetailPage from './AgendaDetail';
import AttendeeDetailPage from './AttendeeDetail';
import AttendeesPage from './Attendees';
import FeedbackPage from './FeedbackSingle';
import DetailsPage from './Details';
import PresenterPage from './Presenter';
import FeedbackDetailPage from './FeedbackDetail';
import ContentPage from './Content';
import ContentItemDetailPage from './ContentItemDetail';
import AgendaContentItemDetailPage from './AgendaContentItemDetail';

const AppStack = () => (
  <div>
    <IonTabs>
      <IonRouterOutlet>
        <Route path="/:tab(agenda)" component={SchedulePage} exact={true} />
        <Route path="/:tab(agenda)/item" component={AgendaDetailPage} exact={true} />
        <Route path="/:tab(agenda)/item/:id/presenter" component={PresenterPage} exact={true} />
        <Route path="/:tab(agenda)/item/:id/content" component={AgendaContentItemDetailPage} exact={true} />
        <Route path="/:tab(attendees)" component={AttendeesPage} exact={true} />
        <Route path="/:tab(attendees)/attendee" component={AttendeeDetailPage} exact={true} />
        <Route path="/:tab(feedback)" component={FeedbackPage} exact={true} />
        <Route path="/:tab(feedback)/form" component={FeedbackDetailPage} />
        <Route path="/:tab(details)" component={DetailsPage} />
        <Route path="/:tab(content)" component={ContentPage} exact={true} />
        <Route path="/:tab(content)/content" component={ContentItemDetailPage} exact={true} />
      </IonRouterOutlet>
      <IonTabBar currentPath={''} slot="bottom">
        <IonTabButton tab="details" href="/details">
          <IonIcon src="/icons/icon-logo.svg" />
          <IonLabel>Details</IonLabel>
        </IonTabButton>
        <IonTabButton tab="attendees" href="/attendees">
          <IonIcon src="/icons/icon-attendees.svg" />
          <IonLabel>Attendees</IonLabel>
        </IonTabButton>
        <IonTabButton tab="agenda" href="/agenda">
          <IonIcon src="/icons/icon-cal.svg" />
          <IonLabel>Agenda</IonLabel>
        </IonTabButton>
        <IonTabButton tab="feedback" href="/feedback">
          <IonIcon src="/icons/icon-feedback.svg" />
          <IonLabel>Feedback</IonLabel>
        </IonTabButton>
        <IonTabButton tab="content" href="/content">
          <IonIcon src="/icons/icon-folder.svg" />
          <IonLabel>Content</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  </div>
);

export default AppStack;
